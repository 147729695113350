import React, {
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineClose } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

function MyDropzone(props, _ref) {
  const [files, setFiles] = useState([]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      let checkSize = false;
      let divText = "";

      acceptedFiles.map((file) => {
        if (file.size >= 5000000) {
          divText += `
            <div>
              Il file ${file.name} supera i 5MB, caricare una versione di
              dimensioni ridotte
            </div>`;
          checkSize = true;
        }
      });

      if (checkSize === true) {
        const errorDropzone = document.getElementById("error-dropzone");
        errorDropzone.innerHTML = divText;
        return;
      }

      if (files.length === 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              id: uuidv4(),
              preview: URL.createObjectURL(file),
            })
          )
        );
      } else {
        let newFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            id: uuidv4(),
            preview: URL.createObjectURL(file),
          })
        );
        setFiles([...files, ...newFiles]);
      }
    },
    accept: { "image/*": [] },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleRemoveFile = (id) => {
    setFiles((current) =>
      current.filter((file) => {
        // 👇️ remove object that has id equal to 2
        return file.id !== id;
      })
    );
  };

  const thumbs = files.map((file, i) => (
    <div style={thumb} key={file.id} className="relative">
      <div
        className="absolute -right-1 -top-1"
        onClick={() => handleRemoveFile(file.id)}
      >
        <div className="cursor-pointer w-[20px] h-[20px] bg-gray-mid rounded-full border flex items-center justify-center">
          <AiOutlineClose className=" text-black text-xs" />
        </div>
      </div>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useImperativeHandle(_ref, () => ({
    getAllFiles: () => {
      return files;
    },
  }));

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} id="data-files" />
        {isDragActive ? (
          <p className="text-sm">Trascina i file ora...</p>
        ) : (
          <p className="text-sm">
            Clicca e seleziona le tue immagini, o trascinale in questo spazio
            <br />
            <span className="text-xs pt-4">
              (Formati accettati: jpg/jpeg/png - Massimo 5MB per foto)
            </span>
          </p>
        )}
      </div>
      <div style={thumbsContainer}>{thumbs}</div>
    </>
  );
}

export default React.forwardRef(MyDropzone);
