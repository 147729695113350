import { FaSpinner } from "react-icons/fa";

const LoadingImage = ({ isLoading }) => {
  if (!isLoading) {
    return;
  }
  return (
    <div className="absolute inset-0 bg-black/70 z-30">
      <div className="h-full w-full flex items-center">
        <div className="bg-white max-w-[700px] mx-auto py-10">
          <div className="pb-5">
            <FaSpinner className="text-7xl h-full flex justify-center items-center m-auto animate-spin text-gray-400" />
          </div>
          <div className="text-center font-body text-color-red text-4xl max-w-[500px] leading-relaxed">
            Stiamo preparando la tua immagine...
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingImage;
