import { useState } from "react";

const TextAreaCounter = ({ descriptionUser }) => {
  const [counter, setCounter] = useState(0);

  return (
    <div className="relative">
      <textarea
        name="descriptionUser"
        placeholder="Inserisci la tua descrizione... "
        style={{ resize: "none" }}
        className="border w-full h-full p-2 focus:outline-none mb-8 text-sm"
        maxLength="1000"
        ref={descriptionUser}
        onChange={(e) => setCounter(e.target.value.length)}
        maxLength="1000"
        rows="4"
        id="err-msg-description"
      ></textarea>
      <span className="font-title text-sm absolute bottom-2 right-0">
        Caratteri
        <span className="pl-2">{counter}</span> / 1000
      </span>
    </div>
  );
};

export default TextAreaCounter;
