import Wrapper from "../comps/common/wrapper";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import checkDate from "../utils/checkDate";
import axios from "axios";
import axiosConfig from "../utils/config/const";
import PreviewRicordo from "../comps/preview.ricordo";

const {
  axiosConfig: { timeout },
} = axiosConfig;
const api_base_url_bacheca = process.env.REACT_APP_API_BACHECA;

const ListaRicordiEditionPage = () => {
  const { id } = useParams();
  const [results, setResults] = useState([]);

  useEffect(() => {
    /**
     * Check if date is valid, if valid make request to see all memory related to this date
     */
    let dateParts = id.split(".");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    /**
     * Do request only if valid date
     */
    if (checkDate(dateObject)) {
      axios({
        method: "get",
        url: `${api_base_url_bacheca}remembers/list?editions=${id}`,
        timeout: timeout,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data?.data) {
              const myEditionDate = Object.keys(response.data.data);
              myEditionDate.map((edition) => {
                setResults(response.data.data[edition].items);
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return (
    <Wrapper>
      <div className="lista-ricordi-edition">
        <div className="font-title font-bold text-2xl p-12 border-b py-6">
          Ecco i ricordi disponibili per l'edizione del{" "}
          <span className="text-color-red">{id}</span>
        </div>
        <div>
          {results !== undefined &&
            results.length >= 1 &&
            results.map((memory) => {
              return (
                <div className="m-5 ricordo" key={memory.id}>
                  <PreviewRicordo memory={memory} />
                </div>
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
};

export default ListaRicordiEditionPage;
