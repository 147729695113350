import Wrapper from "../comps/common/wrapper";

const NotFoundPage = () => {
  return (
    <Wrapper>
      <div className="not-found py-20 sm:py-52">
        <div className="text-center">
          <div className="font-title text-9xl text-color-red font-normal big">
            404
          </div>
          <div className="text-color-red text-3xl pt-2">
            Oops! Ci dispiace informarti che la pagina richiesta non è stata
            trovata
          </div>
          <div className="font-body pt-10 text-lg font-light">
            Ritorna alla HomePage del Corriere del Ticino per continuare a
            fruire i nostri contenuti
            <div className="text-color-red ">
              <a
                href="/"
                className="text-color-red focus:text-color-red hover:text-color-red hover:underline"
              >
                Clicca qui per tornare alla HomePage
              </a>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NotFoundPage;
