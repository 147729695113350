const Intro = () => {
  return (
    <div className="p-10 py-12 text-center text-2xl md:text-3xl max-w-[500px] mx-auto md:py-36 md:px-0">
      <div className="mb-4 leading-relaxed">
        Scopri l’Archivio Storico del{" "}
        <span className="text-color-red font-bold">Corriere del Ticino</span>,
        uno strumento per vivere il passato, condividere i ricordi <br />e
        immaginare il futuro.
      </div>
      <div className="pt-2 text-uppercase color-red font-bold text-2xl md:text-3xl leading">
        Il presente di oggi è la storia <br />
        di domani.
      </div>
    </div>
  );
};

export default Intro;
