import { FaArrowAltCircleRight } from "react-icons/fa";
import Wrapper from "../comps/common/wrapper";
import FaqLink from "../comps/faq-link";
import FaqSubtitle from "../comps/faq-subtitle";

const FaqPage = () => {
  const handleToggle = (e) => {
    /**
     * Get first sibling with specific class (subtext) and toggle hidden
     */
    const parent = e.target.parentNode;
    parent.querySelector(".subtext").classList.toggle("hidden");
    parent.querySelector(".icon").classList.toggle("rotate-90");
  };
  return (
    <Wrapper>
      <div className="container p-20 mx-auto">
        <div className="font-text font-body text-color-red text-6xl bo">
          FAQ
        </div>
        <div className="font-subtitle font-light text-xl pt-10">
          Il progetto dell’Archivio Storico nasce su mandato della Fondazione
          per il Corriere del Ticino.
        </div>
        <div className="font-subtitle font-light text-xl pt-3 leading-9">
          L’obiettivo è rendere disponibile e consultabile digitalmente
          l’immenso patrimonio finora conservato negli archivi fisici a Muzzano.
          Il lavoro di digitalizzazione delle passate edizioni del Corriere del
          Ticino è partito nel 2020 ed è tutt’ora in corso. In totale verranno
          digitalizzate più di 530'000 pagine che coprono 111 anni di storia,
          dal 1891 al 2002, anno in cui si è iniziato a conservare le edizioni
          già in formato elettronico.
        </div>
        <div className="font-subtitle font-light text-xl pt-3 leading-9">
          Per scoprire tutte le funzionalità del portale, conoscere le modalità
          di ricerca e capire come inviare il tuo contributo alla “bacheca dei
          ricordi” consulta le “Domande frequenti” qui di seguito. Se il tuo
          quesito non ha trovato risposta, saremo felici di aiutarti. Trovi
          tutti i nostri recapiti nella sezione “Contatti”.
        </div>

        <FaqSubtitle subtitle="DOMANDE VARIE" />

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Chi può accedere ai contenuti del portale “Archivio Storico del
            Corriere del Ticino”?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Tutti i detentori di un abbonamento cartaceo, E-Paper o web al
            Corriere del Ticino possono accedere gratuitamente all’Archivio
            Storico e a tutti i suoi contenuti. L’accesso avviene mediante le
            medesime credenziali usate per l’abbonamento. In alcuni periodi, in
            occasione di specifiche attività promozionali, l’Archivio Storico
            del Corriere del Ticino, può essere accessibile a tutti.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Quali edizioni e quali anni di pubblicazione sono a disposizione in
            questo portale?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            L’obiettivo è presentare tutte le edizioni del Corriere del Ticino a
            partire dalla prima uscita del 28 dicembre del 1891. L’Archivio
            storico viene aggiornato costantemente con nuovi anni e nuove
            pubblicazioni. Per permetterti di capire se gli anni di tuo
            interesse sono già disponibili, prova a fare una “ricerca per data”.
            In homepage trovi un’indicazione generale sulla percentuale di
            pagine scansionate disponibili.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Come posso accedere al portale Archivio Storico se non sono
            abbonato?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            In alcuni periodi, in occasione di specifiche attività promozionali,
            l’Archivio Storico del Corriere del Ticino, può essere accessibile a
            tutti. Di norma, però, serve un abbonamento al Corriere del Ticino.
            Puoi scegliere l’abbonamento più adatto a te da
            <a
              className="text-color-red underline px-2"
              target="_blank"
              href="https://www.cdt.ch/abbonati"
            >
              www.cdt.ch/abbonati
            </a>
            o contattando il nostro Servizio Clienti (
            <a
              className="text-color-red underline px-2 pl-0"
              href="mailto:servizioclienti@cdt.ch"
            >
              servizioclienti@cdt.ch
            </a>{" "}
            / 091 960 31 13).
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Dove trovo l’edizione del Corriere del Ticino di oggi o le edizioni
            degli ultimi anni?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            L’edizione del giorno del Corriere del Ticino, e le edizioni dei 2
            anni precedenti, non sono visibili nell’Archivio Storico, ma sono
            disponibili nello sfogliatore. Le puoi consultare tramite l’App CdT
            E-Paper (disponibile su
            <a
              className="text-color-red underline px-2"
              href="https://apps.apple.com/ch/app/cdt-e-paper/id391355824"
              target="_blank"
            >
              App Store
            </a>
            e su
            <a
              className="text-color-red underline px-2 pr-0"
              href="https://play.google.com/store/apps/details?id=ch.cdt.corrieredelticino&hl=it&gl=US"
              target="_blank"
            >
              Google Play
            </a>
            ) o accedendo a
            <FaqLink
              link="https://digital.cdt.ch/"
              text="www.digital.cdt.ch."
              target="_blank"
            />
          </div>
        </div>

        <FaqSubtitle subtitle="DOMANDE TECNICHE" />

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Da quale dispositivo è possibile utilizzare il portale Archivio
            Storico?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            È possibile utilizzare il portale Archivio Storico sia da
            dispositivi desktop che mobile. La navigazione è ottimizzata in
            entrambi i casi, ma consigliamo la visualizzazione da desktop o
            tablet per un’esperienza di lettura più agevole.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            È necessario scaricare un’applicazione per utilizzare il portale
            Archivio Storico?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Non è necessario scaricare un’applicazione poiché il portale è
            accessibile tramite il seguente link:{" "}
            <FaqLink
              text="Archivio Storico"
              link="/"
              target="_self pl-0 inline-block"
            />
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Necessito di un browser in particolare per utilizzare il portale
            Archivio Storico?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Il portale Archivio Storico è visualizzabile dai browser più comuni
            come: Google Chrome, Mozilla Firefox, Microsoft Edge e Safari,
            aggiornati alle ultime versioni.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Vorrei segnalare una problematica o un suggerimento, a chi mi posso
            rivolgere?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Si può scrivere un’e-mail a:{" "}
            <FaqLink
              text="servizioclienti@cdt.ch"
              link="mailto:servizioclienti@cdt.ch"
              specialClass="pl-0 pr-0"
            />
            . Sarà nostra premura dare una risposa il prima possibile.
          </div>
        </div>

        <FaqSubtitle subtitle="CAMPO RICERCA" />

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Come utilizzare il campo ricerca?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            <div>La ricerca è possibile in 2 modi diversi.</div>
            <div className="pt-3">
              <span className="underline">Ricerca tramite parola chiave:</span>{" "}
              la ricerca necessita di una o più parole chiavi e, volendo, di un
              periodo temporale specifico per restituire un risultato. Nel campo
              “ricerca” è necessario scrivere la parola o le parole di proprio
              interesse, mentre il periodo di ricerca desiderato si può
              scegliere selezionando sul calendario una data di inizio e una
              data di fine (la ricerca è possibile in un arco temporale di
              massimo 10 anni). Se si desidera che la ricerca venga effettuata
              in un’unica edizione, la data di inizio e di fine possono
              coincidere. Il sistema cerca tutte le edizioni in cui compaiono i
              termini digitati all’interno del periodo di tempo selezionato,
              restituendo le singole pagine. I risultati possono venir
              restituiti partendo dal più vecchio o dal più nuovo. Si può fare
              una ricerca per una o più parole chiave (es. Mario Rossi: verranno
              cercate tutte le edizioni che riportano entrambe le parole anche
              in ordine sparso) oppure per "frase esatta" (es. Mario Rossi:
              verranno cercate tutte le edizioni che riportano il nome Mario
              Rossi scritto in questo ordine).
            </div>
            <div className="pt-3">
              <span className="underline">Ricerca tramite data:</span> la
              ricerca necessità di una data specifica o di un arco temporale con
              una data di inizio e di fine (la ricerca è possibile in un arco
              temporale di massimo 10 anni). Il sistema cerca tutte le edizioni
              nell’arco temporale desiderato restituendo per ogni data, l’intera
              edizione del giorno.
            </div>
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Che cos’è una parola chiave?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Una parola chiave è un termine che si intende ricercare
            nell’Archivio Storico del Corriere del Ticino.
          </div>
        </div>

        <FaqSubtitle subtitle="RISULTATO RICERCA" />

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Non trovo il giornale in una data specifica, come mai?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Potrebbe essere che il giornale nella data ricercata non è stato
            pubblicato a causa di un giorno festivo oppure rientra tra quelli
            non ancora disponibili.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Che cosa restituisce il risultato della mia ricerca?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Il risultato della ricerca effettuata restituisce in ordine
            cronologico tutte le pagine dov’è presente una o più parole chiavi
            inserite nel campo ricerca. Viene visualizzato un certo numero di
            risultati per volta. Possono essere presenti più pagine, ognuna con
            diversi risultati.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Come posso visualizzare l’articolo di mio interesse?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            È necessario selezionare l’immagine della pagina di giornale in
            anteprima nel risultato di ricerca, cliccando sulla data che compare
            sopra l’immagine stessa.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Come posso scaricare l’articolo che mi interessa?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            È possibile scaricare unicamente le singole pagine, che sono tutte
            caratterizzate da una filigrana. Non è possibile scaricare in una
            volta sola un’intera edizione. Le pagine scaricate sono
            esclusivamente per uso privato e non possono venir impiegate in
            ambiti pubblicitari, commerciali, pubblici e via dicendo.
          </div>
        </div>

        <FaqSubtitle subtitle="BACHECA DEI RICORDI" />

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Che cosa sono i ricordi?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            I ricordi sono la nostra memoria collettiva. La bacheca dei ricordi
            contiene i vostri contributi. Se leggendo un articolo del nostro
            archivio vi viene in mente un fatto particolare legato a quel
            determinato evento, potete partecipare anche voi alla creazione di
            questa memoria collettiva condividendo il vostro ricordo, con testi
            e foto, in questa bacheca.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Chi inserisce i ricordi?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            I ricordi possono venir inseriti da tutti coloro che accedono
            all’Archivio Storico.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Che caratteristiche deve rispettare un ricordo? C’è un regolamento
            da rispettare?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Il ricordo può essere di qualsiasi tipo nel rispetto dell’etica,
            delle leggi e della costruttiva partecipazione al progetto. Il
            limite massimo di ogni ricordo è di 1'000 caratteri. Non vengono
            accettati ricordi con contenuti offensivi, lesivi dell’immagine di
            terzi, razzisti, che incitano all’odio o alla violenza o che, in
            qualsiasi modo, si pongano al di fuori della legalità. Sono
            accettati ricordi che contengono critiche costruttive se queste sono
            un valore aggiunto per il dialogo e la tolleranza. In caso di
            ricordi che coinvolgono più persone rispetto all’autore stesso, è
            onere e responsabilità di quest’ultimo ottenere il consenso dai
            diretti interessati sia per la loro citazione testuale che per l’uso
            di eventuali loro immagini rappresentate in fotografia. Il Corriere
            del Ticino declina ogni responsabilità, si riserva la possibilità di
            non pubblicare e/o di cancellare un contributo inviato, come anche
            la possibilità di apportare lievi modifiche ai testi, purché non ne
            compromettano il senso (es. Correzione di errori ortografici,
            anonimizzazione di dati personali, …).
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Come posso aggiungere un ricordo?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Per aggiungere un ricordo devi aver effettuato l’accesso con le tue
            credenziali. Per inserire un ricordo è necessario cercare prima
            nella home l’articolo/pagina per il/la quale si desidera scriverlo e
            cliccare sul pulsante apposito “Inserisci ricordo”. Una volta
            selezionato si aprirà una schermata dedicata dove è possibile
            indicare i dettagli necessari alla pubblicazione: Nome, Cognome
            (sarà visibile solo la prima lettera), comune di residenza, una mail
            valida, testo ed eventuali immagini. Se si desidera aggiungere
            un’immagine al proprio ricordo è possibile caricare un file .PNG o
            .JPG. Per poter pubblicare il ricordo è obbligatorio accettare la
            <FaqLink
              link="https://privacy.gruppocdt.ch/it/privacy-policy/web/cdt-archiviostorico"
              target="_blank"
              text="Privacy Policy"
              specialClass="pr-1"
            />
            .
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            È possibile aggiungere un ricordo anonimo?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Siamo convinti che la Storia con la S maiuscola sia fatta
            dall’insieme di tutte le nostre storie personali. Per questo motivo,
            per valorizzare le persone, non procediamo con la pubblicazione di
            ricordi anonimi. Il tuo ricordo apparirà con il nome di battesimo e
            la sola iniziale del cognome.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Quanto testo posso scrivere?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Il ricordo può avere una lunghezza massima di 1'000 caratteri spazi
            inclusi e di 80 caratteri spazi inclusi per il titolo dato al
            ricordo.
          </div>
        </div>

        <div className="font-text font-body pt-10  relative question-block">
          <div className="icon absolute -left-9 top-12">
            <FaArrowAltCircleRight className="text-color-red  text-2xl" />
          </div>
          <div
            className="question text-color-red text-3xl hover:underline hover:cursor-pointer"
            onClick={(e) => handleToggle(e)}
          >
            Una volta inserito il mio ricordo, cosa succede?
          </div>
          <div className="subtext pt-5 text-black font-subtitle font-light leading-9 text-xl hidden">
            Il tuo contributo arriva al Corriere del Ticino che, se rispetta il
            regolamento, procederà a renderlo visibile a tutti gli utenti il
            prima possibile. Una volta accettato, ti sarà inviata una mail di
            conferma di pubblicazione del ricordo.
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default FaqPage;
