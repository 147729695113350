import { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import Wrapper from "../comps/common/wrapper";
import axios from "axios";
import PreviewRicordo from "../comps/preview.ricordo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundOverlay from "../comps/common/bg-overlay";
import { Helmet } from "react-helmet";

const BachecaDeiRicordiPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: "https://archivmanager.cdt.ch/api/remembers/latest",
      timeout: 15000,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Archivio Storico CdT - Bacheca Dei Ricordi</title>
        <meta
          name="description"
          content="Rivivi gli avvenimenti del passato. Condividi con gli altri lettori i tuoi ricordi e contribuisci alla memoria storica del nostro Cantone."
        />
      </Helmet>
      <div className="flex border-b bacheca-ricordi-page flex-col xl:flex-row">
        <div
          className="flex-1 relative"
          style={{
            backgroundImage: `url('https://assets.gruppocdt.ch/archiviostorico/pattern-giornale.png')`,
            width: "100%",
            height: "100%",
          }}
        >
          <BackgroundOverlay />
          <div className="z-50 flex justify-center items-center h-full relative flex-col ">
            <div className="article-box bg-white inline-block w-full max-w-[400px]  md:max-w-[500px] m-10">
              <div className="font-title font-medium text-xl text-black bg-white p-5 border-b text-center uppercase">
                LE VOSTRE
                <span className="text-color-red pl-2 font-extrabold">
                  CARTOLINE
                </span>
              </div>
              <div className="flex m-7 flex-col">
                <Slider {...settings}>
                  <div>
                    <img
                      src="https://assets.gruppocdt.ch/archiviostorico/1_Tenda_Botta_CMYK.jpg"
                      alt="ricordo 1 - immagine"
                    />
                  </div>

                  <div>
                    <img
                      src="https://assets.gruppocdt.ch/archiviostorico/4_San_Carlino.jpg"
                      alt="ricordo 4 - immagine"
                    />
                  </div>

                  <div>
                    <img
                      src="https://assets.gruppocdt.ch/archiviostorico/Papa%20a%20Cornaredo.jpg"
                      alt="ricordo 12 - immagine"
                    />
                  </div>
                  <div>
                    <img
                      src="https://assets.gruppocdt.ch/archiviostorico/SGottardo_BN.jpg"
                      alt="ricordo 13 - immagine"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 p-10 px-12 items-center flex">
          <div className="font-body bacheca-text">
            <div className=" text-color-red text-center text-4xl font-bold">
              Rivivi gli avvenimenti del passato
            </div>
            <div className="mt-5 text-center text-2xl text-black text-loose font-light">
              Condividi con gli altri lettori i tuoi ricordi e contribuisci alla
              memoria storica del nostro Cantone. Di seguito puoi leggere gli
              ultimi ricordi inseriti. Se vuoi condividere anche tu un ricordo
              puoi cercare in homepage l’edizione di tuo interesse e cliccare su
              “Inserisci il tuo ricordo”.
            </div>
          </div>
        </div>
      </div>

      <div className="bacheca-ricordi-editions">
        <div className="font-title font-bold text-2xl p-12 border-b py-6">
          Gli ultimi ricordi inseriti
        </div>
        <div className="m-10 md:border-b  font-bold text-3xl pb-6">
          {isLoading && (
            <div className="text-center">
              <FaSpinner className="mx-auto text-6xl animate-spin text-slate-900" />
            </div>
          )}
          {data.map((memory) => {
            return <PreviewRicordo memory={memory} key={memory.id} />;
          })}
        </div>
      </div>
    </Wrapper>
  );
};

export default BachecaDeiRicordiPage;
