import { Parser } from "html-to-react";
const htmlParser = new Parser();

export default function getContent(block) {
  switch (block.type) {
    case "paragraph":
      return (
        <div className="text-2xl font-light block leading-10">
          {htmlParser.parse(block.data?.text)}
        </div>
      );
    case "image":
      return (
        <img
          src={block.data?.file?.url}
          alt={block.id}
          className="w-full block"
        />
      );
  }
}
