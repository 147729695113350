import Wrapper from "../comps/common/wrapper";
import Intro from "../comps/intro";
import { useEffect, useState } from "react";
import axios from "axios";
import SearchBlock from "../comps/search-block/search-block";
import DailyArticleZone from "./homepage/daily-article-zone";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Helmet } from "react-helmet";

const HomePage = () => {
  // Used to manage status bar of the years of completition of the scannering process
  const [arrStatusScan, setArrStatusScan] = useState([]);
  const [statusScanPercante, setStatusScanPercante] = useState();

  useEffect(() => {
    /**
     * Get Status of Current Generated ARTICLES range of years
     */
    const api_base_url = process.env.REACT_APP_API_URL;
    axios
      .get(`${api_base_url + "/v2/status"}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data?.completion) {
            setArrStatusScan(response.data.completion.years);
            setStatusScanPercante(response.data.completion.extPercent);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Archivio CdT</title>
        <meta
          name="description"
          content="Scopri le passate edizioni del Corriere del Ticino. Tante funzionalità per vivere il passato, condividere i tuoi ricordi e immaginare il futuro."
        />
      </Helmet>
      <div className="flex flex-col md:flex-row relative">
        <div className="grid md:grid-cols-2 w-full border-b">
          <div className="title-block">
            <Intro />
          </div>
          <DailyArticleZone />
        </div>
      </div>

      <div className="py-20 md:py-32 scan-container relative">
        <div className="px-10 md:px-20 text-center font-body font-thin text-black text-2xl md:text-3xl">
          La digitalizzazione delle vecchie edizioni del Corriere del Ticino è
          un progetto in corso e l’archivio viene continuamente arricchito con
          nuovi anni.
          {arrStatusScan.length >= 1 && (
            <>
              <span className="text-color-red font-bold pr-2 pl-2">
                Ad oggi è disponibile il {statusScanPercante}
              </span>
              di tutte le pagine mai pubblicate. Torna spesso a trovarci per
              scoprire i nuovi contenuti.
              <div className="text-center flex items-center text-base  text-color-red mt-2">
                <span className="flex items-center mx-auto">
                  <AiOutlineInfoCircle />
                  <a
                    href="https://static.gruppocdt.ch/utils-uploads/2024/08/05/dd79ea0f-42ce-4d19-b11f-46635f0c8e1c/AnniDisponibiliASUpdateAgosto24.pdf"
                    target="_blank"
                    className="ml-1 text-color-red hover:text-color-red focus:text-color-red"
                  >
                    Consulta lo stato di avanzamento dell'Archivio Storico
                  </a>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <SearchBlock />
    </Wrapper>
  );
};

export default HomePage;
