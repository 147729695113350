const CarouselEditions = ({ editions, current }) => {
  function checkLoading(index) {
    if (index + 1 === editions.length) {
      const element = document.getElementById(current);
      if (element) {
        if (window.innerWidth <= 767) {
          var leftPos = element.offsetLeft;
          document.getElementsByClassName("edition-carousel")[0].scrollLeft =
            leftPos;
        } else {
          var topPos = element.offsetTop;
          document.getElementsByClassName("edition-carousel")[0].scrollTop =
            topPos + -20;
        }
        element.classList.add("active");
      }
    }
  }

  return (
    <div className="flex flex-row md:flex-col bg-gray-100">
      {editions.map((issue, index) => {
        return (
          <div
            className="md:pb-5 pl-5 md:pl-0 edition"
            id={issue.docUid}
            key={issue.docUid}
          >
            <a href={`/pagina/${issue.docUid}`}>
              <div className="relative">
                <img
                  src={issue.pageImages.micro}
                  alt={`Pagina numero: ${issue.pageNumber}`}
                  className="max-w-[75px] mx-auto border-color-red"
                  onLoad={() => checkLoading(index)}
                />
              </div>
              <div className="text-center pt-1">{issue.pageNumber}</div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default CarouselEditions;
