import { useImperativeHandle, useState } from "react";

const RadioArea = ({ radioStateRef }) => {
  const [adsRadio, setAdsRadio] = useState(false);

  const handleChangeStatus = (e, param) => {
    setAdsRadio((prev) => !prev);
  };

  useImperativeHandle(radioStateRef, () => ({
    getRadio: () => {
      return adsRadio;
    },
  }));

  return (
    <>
      <div class="flex items-center pt-1 pr-1">
        <input
          type="radio"
          value="true"
          onChange={(e) => handleChangeStatus(e)}
          name="default-radio-1"
          id="default-radio-1"
          checked={adsRadio === true}
          class="w-4 h-4 text-color-red  bg-gray-100 border-gray-300  dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 accent-color-red"
        />
        <label
          htmlFor="default-radio-1"
          class="ml-1 text-xs font-light text-black"
        >
          ACCONSENTO
        </label>
      </div>
      <div class="flex items-center ml-2 pt-1">
        <input
          checked={adsRadio === false}
          type="radio"
          value="false"
          id="default-radio-2"
          name="default-radio-2"
          onChange={(e) => handleChangeStatus(e)}
          class="w-4 h-4  text-color-red bg-color-red border-color-red dark:ring-offset-color-red  dark:bg-gray-700 dark:border-gray-600 accent-color-red"
        />
        <label
          htmlFor="default-radio-2"
          class="ml-1 text-xs font-light text-black"
        >
          NON ACCONSENTO
        </label>
      </div>
    </>
  );
};

export default RadioArea;
