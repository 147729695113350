import { useImperativeHandle, useState } from "react";
import InputMaskVendor from "react-input-mask";

const InputMask = ({
  datePickerRefFrom,
  datePickerRefTo,
  datePickerRefToSet,
}) => {
  const [inputData, setInputData] = useState();
  const [inputData2, setInputData2] = useState();

  const year1 = /[1-2]/;
  const year2 = /[9,0]/;
  const year3 = /[0-9]/;
  const year4 = /[0-9]/;
  const month1 = /[0-2]/;
  const month2 = /[0-9]/;
  const day1 = /[0-3]/;
  const day2 = /[0-9]/;
  const mask = [
    day1,
    day2,
    "-",
    month1,
    month2,
    "-",
    year1,
    year2,
    year3,
    year4,
  ];

  useImperativeHandle(datePickerRefFrom, () => ({
    getInputMaskDataFrom: () => {
      return inputData;
    },
  }));

  useImperativeHandle(datePickerRefTo, () => ({
    getInputMaskDataTo: () => {
      return inputData2;
    },
  }));

  useImperativeHandle(datePickerRefToSet, () => ({
    setInputMaskDataTo: () => {
      return setInputData2();
    },
  }));

  return (
    <div className="flex flex-row">
      <div className="w-full ">
        <div className="text-gray-500 text-xs pb-1">Da</div>
        <InputMaskVendor
          mask={mask}
          maskPlaceholder="gg-mm-aaaa"
          alwaysShowMask={true}
          onChange={(e) => setInputData(e.currentTarget.value)}
        >
          <input type="text" name="date-mask" className="w-full" />
        </InputMaskVendor>
      </div>
      <div className="w-full ml-5 ">
        <div className="text-gray-500 text-xs pb-1">A</div>
        <InputMaskVendor
          mask={mask}
          maskPlaceholder="gg-mm-aaaa"
          alwaysShowMask={true}
          onChange={(e) => setInputData2(e.currentTarget.value)}
          value={inputData2}
        >
          <input type="text" name="date-mask-2" className="w-full" />
        </InputMaskVendor>
      </div>
    </div>
  );
};

export default InputMask;
