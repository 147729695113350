import { FaSpinner } from "react-icons/fa";

const Overlay = () => {
  return (
    <div className="overlay  bg-slate-500/50 z-20 absolute inset-0">
      <div className="fixed z-30 inset-0">
        <FaSpinner className="text-6xl h-full flex justify-center items-center m-auto animate-spin text-slate-900" />
      </div>
    </div>
  );
};

export default Overlay;
