export default function formatAllDateIsoStandard(data, format) {
  let dateParts;
  let dateObject;
  if (data === undefined) {
    return;
  }
  switch (format) {
    case "point":
      dateParts = data.split(".");
      dateObject = new Date(data);
      return dateObject;
    case "dash":
      dateParts = data.split("-");
      dateObject = new Date(data);
      return dateObject;
    default:
      return;
  }
}
