import BackgroundOverlay from "../common/bg-overlay";
import SearchContainer from "../search-container";

const SearchBlock = ({ setResults }) => {
  return (
    <div className="search-container-box p-10 px-5 md:p-20 relative">
      <BackgroundOverlay zIndex={"z-10"} />
      <div className="text-shadow font-title text-4xl md:text-5xl  font-medium text-center leading-10 z-50 relative text-white">
        Il Corriere del Ticino spalanca le sue pagine ai lettori dal 1891
        <div className="text-color-red pt-5">
          Cerca l’anno o l’evento di tuo interesse
        </div>
      </div>
      <SearchContainer setResults={setResults} />
    </div>
  );
};

export default SearchBlock;
