import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./scss/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import IubendaInit from "./comps/common/iubenda-init";
import { UserContextProvider } from "./store/auth-context";

import Piano from "./comps/common/piano";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <UserContextProvider>
      <Piano />
      <BrowserRouter>
        <App />
        <IubendaInit />
      </BrowserRouter>
    </UserContextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
