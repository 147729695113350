const BackgroundOverlay = ({ zIndex, opacity }) => {
  return (
    <div
      className={`w-full ${
        opacity ? opacity : "bg-black/70"
      } absolute inset-0 ${zIndex ? zIndex : ""}`}
    ></div>
  );
};

export default BackgroundOverlay;
