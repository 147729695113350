import { useState, useEffect } from "react";
import axios from "axios";

const DailyArticleZone = () => {
  const [dailyArticle, setDailyArticle] = useState(); // single data used for the daily 100 anni article tag in home page

  useEffect(() => {
    /**
     * Get Daily Article from tags generated by naxos 100 anni fa
     */
    const API_NAXOS_BASE = process.env.REACT_APP_NAXOS_API;
    axios
      .get(`${API_NAXOS_BASE + "tags/2378/stories"}`)
      .then((response) => {
        if (
          response.status !== 200 ||
          response.data.data.stories.length === 0
        ) {
          return;
        }
        setDailyArticle(response.data.data.stories[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      className="intro-article-container "
      style={{
        backgroundImage: `url('https://assets.gruppocdt.ch/archiviostorico/sfondo-cdt.png')`,
        width: "100%",
        height: "100%",
      }}
    >
      <div className=" z-50 flex justify-center items-center h-full relative flex-col ">
        <div className="article-box bg-white inline-block  max-w-[400px] m-10">
          <div className="font-title font-medium text-xl text-black bg-white p-5 border-b text-center uppercase">
            UN SECOLO
            <span className="text-color-red pl-2 font-extrabold">
              IN RUBRICA
            </span>
          </div>
          {dailyArticle && (
            <article className="flex m-7 flex-col">
              <a href={"https://www.cdt.ch" + dailyArticle.URL} target="_blank">
                <div className="flex-1">
                  <img
                    src={dailyArticle.cover["960x640"]}
                    alt={dailyArticle.title}
                  />
                </div>
                <div className="flex-1 pt-2 font-body font-bold text-black text-2xl">
                  <span className=" text-color-red pr-2">
                    {dailyArticle.kicker}
                  </span>
                  {dailyArticle.title}
                  <div className="font-title font-light text-base pt-2">
                    {dailyArticle.subtitle}
                  </div>
                </div>
              </a>
            </article>
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyArticleZone;
