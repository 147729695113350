const FaqLink = ({ link, text, target, specialClass }) => {
  return (
    <a
      className={`text-color-red underline px-2 ${
        specialClass ? specialClass : ""
      }`}
      href={link}
      target={target}
    >
      {text}
    </a>
  );
};

export default FaqLink;
