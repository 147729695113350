import { useEffect, useState } from "react";
import Wrapper from "../comps/common/wrapper";
import { MemoryRouter, useParams } from "react-router-dom";
import axios from "axios";
import Overlay from "../comps/common/overlay";
import BackgroundOverlay from "../comps/common/bg-overlay";
import getContent from "../utils/getContent";
import formatDate from "../utils/formatDate";
import PreviewRicordo from "../comps/preview.ricordo";

const RicordoPage = () => {
  let { id } = useParams(); // take id of the memory
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const [editionDate, setEditionDate] = useState();
  const [issue, setIssue] = useState();

  //State for all memory of this issue
  const [allMemory, setAllMemory] = useState([]);
  const [paginationMemory, setPaginationMemory] = useState();

  useEffect(() => {
    /**
     * Ogni volta che viene settato data prendi edizione data se presente e settalo
     */
    if (editionDate) {
      /**
       * Se l'edizione della data è presente
       * -> fai la chiamata per capire tutti i ricordi legati a questa edition date
       * -> prendi le info su questa edition con le API di domenighetti
       */
      const api_base_url = process.env.REACT_APP_API_URL;
      const dataApi = formatDate(data.edition_date.ISO8601, "original");

      axios
        .post(`${api_base_url + "/v2/seek"}`, {
          glue: "ANY", // any : prende ogni valore che si chiama cosi, strict: prende solo esattamente valore richiesto
          property: "CDT", // che altre property abbiamo?
          day: dataApi,
          resultFormat: {
            detail: "light",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data) {
              setIssue(response.data.data?.results);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      /**
       * Se la data è presente setta anche gli altri ricordi presenti per quel edizione!
       */
      axios({
        method: "get",
        url: `https://archivmanager.cdt.ch/api/remembers/edition/${editionDate}`,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            setAllMemory(response.data.data.data);
            /**
             * Get pagination data
             */
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: `https://archivmanager.cdt.ch/api/remember/${id}`,
      timeout: 15000,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data.data);
          setEditionDate(response.data.data.edition_date?.split?.date);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  return (
    <Wrapper>
      <div id="piano-widget"></div>
      {isLoading && (
        <div className="h-screen w-screen relative">
          <Overlay />
        </div>
      )}
      {data && (
        <div className="memory-container">
          <div>
            <div
              className="relative min-h-[400px] md:min-h-[800px] bg-parallax"
              style={{
                backgroundImage: `url("${data.cover["1920x1080"]}`,
              }}
            >
              <BackgroundOverlay />

              <div className="absolute z-50 top-0 bottom-0 text-white w-full">
                <div className="custom-shadow w-full h-full flex items-center justify-center text-2xl md:text-4xl  xl:text-5xl max-w-[50%] mx-auto xl:leading-12 ">
                  <span className="text-color-red font-body inline-block -ml-10">
                    {data.edition_date?.split?.date}
                    <span className="px-2">: </span>
                    {data.author}{" "}
                    <span className="inline text-white font-bold custom-shadow">
                      {data.intro}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="md:max-w-[70%] mx-auto font-subtitle p-10 memory-content-blocks">
              {data.description?.blocks?.length >= 1 &&
                data.description.blocks.map((block) => {
                  return <div key={block?.id}>{getContent(block)}</div>;
                })}
            </div>
          </div>
        </div>
      )}
      {issue && issue.length >= 1 && (
        <div className="my-24">
          <div className="font-title font-bold text-5xl mb-12 text-center">
            Leggi l’edizione del
            <span className="text-color-red font-bold inline-block pl-2">
              {formatDate(issue[0].issue, "api")}
            </span>
          </div>
          <div className="">
            <a href={`/pagina/${issue[0].uid}_001`} target="_blank">
              <img
                src={issue[0].pageImages.default}
                className="mx-auto max-w-[450px]"
              />
            </a>
          </div>
        </div>
      )}

      {allMemory !== undefined && allMemory.length >= 1 && (
        <div className="md:max-w-[70%] mx-auto font-subtitle p-10 memory-content-blocks">
          <div className="text-2xl font-title font-bold bg-white border-t border-b py-5 mb-5">
            Leggi anche gli altri ricordi di questo avvenimento.
          </div>
          {allMemory.map((memory) => {
            return <PreviewRicordo memory={memory} key={memory.id} />;
          })}
        </div>
      )}
    </Wrapper>
  );
};

export default RicordoPage;
