export default function getElementInputName(name) {
  switch (name) {
    case "name":
      return "err-msg-name";
    case "lastname":
      return "err-msg-lastname";
    case "mail":
      return "err-msg-mail";
    case "residence":
      return "err-msg-residence";
    case "description":
      return "err-msg-description";
    case "title":
      return "intro-user";
  }
}
