import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Wrapper from "../comps/common/wrapper";
import {
  FaSearchPlus,
  FaSearchMinus,
  FaUndo,
  FaFileDownload,
  FaSpinner,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import Swal from "sweetalert2";
import getSwalInterruptLoading from "../utils/getSwalInterruptLoading";
import { useSearchParams } from "react-router-dom";

// used to downlaod in react images
import { saveAs } from "file-saver";
/**
 * Zoom Vendor
 * @returns
 */
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoadingImage from "../comps/loading-img";
import formatDate from "../utils/formatDate";
import BackgroundOverlay from "../comps/common/bg-overlay";
import CarouselEditions from "../comps/view-page/lateral-carousel-editions/carousel-editions";

const ViewPage = () => {
  const [searchParams, setSearchParams] = useSearchParams(); // used to get all params from react router dom
  let { id } = useParams();

  const qsParam = searchParams.get("query"); //  query string of the search

  const api_base_url = process.env.REACT_APP_API_URL;

  // Used for download img
  let interval;
  const [uuidImg, setUuidImg] = useState(); // e dove viene settato l'uuid che mi viene generato quando mando la src dell'immagine del giornale richiesta
  const [imgWithWatermark, setImgWithWatermark] = useState(); // e dove andra a finire l'url con il watermark a processo finito
  const [isLoading, setIsLoading] = useState(false); //gestisce la fase di loading del processo

  //Used to paginate other editions page and taking edition data
  const [issueData, setIssue] = useState();
  const [edition, setEdition] = useState();
  const [isLoadingMainEdition, setIsLoadingMainEdition] = useState(false);
  const [isLoadingEdition, setIsLoadingEdition] = useState(false);
  const [showPagination, setShowPagination] = useState(false);

  const imageRef = useRef(null);

  /**
   * Get Pages other from main + all stories not related to search input
   */
  useEffect(() => {
    let fullURL = `${api_base_url + "/v2/pages/" + `${id}`}`;

    if (qsParam) {
      fullURL += `?terms=${qsParam}`;
    }
    setIsLoadingMainEdition(true);
    axios
      .get(fullURL)
      .then((response) => {
        setIsLoadingMainEdition(false);
        if (response.status === 200) {
          if (response.data?.data) {
            setIssue(response.data.data);
            setShowPagination(true);
          }
        }
      })
      .catch((err) => {
        console.log(err); // test
        setIsLoadingMainEdition(false);
      });
  }, []);

  useEffect(() => {
    // managing arrow moving with keyboard between page, is no issueData is found disabilitate the function
    const keyHandler = (e) => {
      if (!issueData || issueData === null) {
        return;
      }
      if (e.key === "ArrowLeft") {
        if (issueData.prevPage !== null) {
          window.location.href =
            window.location.origin + "/pagina/" + issueData.prevPage.docUid;
        }
        return;
      } else if (e.key === "ArrowRight") {
        if (issueData.nextPage !== null) {
          window.location.href =
            window.location.origin + "/pagina/" + issueData.nextPage.docUid;
        }
        return;
      }
    };

    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, [issueData]);
  /**
   * When first request is gone we finally have issue data of the page user is loading
   * use this data to retrieve ALL THE PAGES of the issue where the page is
   */
  useEffect(() => {
    if (issueData?.issue) {
      setIsLoadingEdition(true);
      axios
        .get(`${api_base_url + `/v2/issues/${issueData?.issue}`}`)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.data) {
              setEdition(response.data.data);
              setIsLoadingEdition(false);
            }
          }
        })
        .catch((err) => {
          setIsLoadingEdition(false);
          console.log(err);
        });
    }
  }, [issueData?.issue]);

  const makeImgWithWatermark = async (url) => {
    /**
     * Take url of the image and make FIRST POST request
     * -> send url asset as post and wait for UID
     */
    var config = {
      method: "post",
      url: "https://archivmanager.cdt.ch/api/utils/watermark",
      timeout: 15000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        image: url,
      },
    };
    setIsLoading(true);
    axios(config)
      .then(function (response) {
        /**
         * If response goes well we have an uuid
         */
        if (response.status === 200) {
          /**
           * Allora settiamo uuid come stato
           */
          setUuidImg(response.data.data.data.uuid);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        getSwalInterruptLoading();
      });
  };
  let myCounter = 0;

  useEffect(() => {
    /**
     * Ogni volta che viene settato un uuid come img fai partire un set interval che fa una richiesta ogni 3 secondi
     * per controllare se l'immagine e stata generata!
     * da fare solo se ce un uuid!
     */
    if (uuidImg) {
      interval = setInterval(() => {
        /**
         * For each iteration increment counter if the iteration comes 5 time and still no image brake the loop
         */
        myCounter++;

        /**
         * If counter is 10, means more than 10 request done too much unset all and fire a swal that say something went wrong try later
         */
        if (myCounter >= 5) {
          setIsLoading(false);
          setUuidImg();
          clearInterval(interval);
          getSwalInterruptLoading();
          return;
        }

        /**
         * Make request with the uuid u have to generate img with watermark
         */
        axios({
          method: "get",
          url: `https://archivmanager.cdt.ch/api/utils/watermark/check?uuid=${uuidImg}`,
          timeout: 15000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function (response) {
            /**
             * If response goes well we have an uuid
             */
            if (response.status === 200) {
              /**
               * Allora settiamo uuid come stato
               */
              setImgWithWatermark(response.data.data.data.url);
              setUuidImg();
              clearInterval(interval);
            }
          })
          .catch(function (error) {
            if (error.request.status !== 404) {
              setIsLoading(false);
              setUuidImg();
              clearInterval(interval);
              getSwalInterruptLoading();
            }
          });
      }, 5000);
    }
  }, [uuidImg]);

  useEffect(() => {
    /**
     * L'immagine con il watermark è pronta!
     */
    if (imgWithWatermark) {
      saveAs(imgWithWatermark, `edizione-corriere-${id}`);
      // downloadImage(imgWithWatermark, `edizione-corriere-${id}`);
      setImgWithWatermark();
      setIsLoading(false);
      Swal.fire({
        title: "Immagine generata con successo!",
        icon: "success",
        text: "L'immagine verra scaricata a breve...",
        timer: 5000,
        timerProgressBar: true,
      });
    }
  }, [imgWithWatermark]);

  /**
   * Used to download page image for user
   */
  const downloadImg = async () => {
    let url = `https://sec-archivio-public.s3.eu-central-1.amazonaws.com/default/${id}.png`;
    await makeImgWithWatermark(url);
  };

  if (isLoadingMainEdition) {
    return (
      <Wrapper>
        <div className="h-screen relative">
          <BackgroundOverlay opacity={"bg-black/20"} />
          <FaSpinner className="text-7xl h-full flex justify-center items-center m-auto animate-spin  text-slate-800" />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div id="piano-widget"></div>
      <LoadingImage isLoading={isLoading} />
      <div className="text-center flex justify-center items-center  view-page relative">
        <div className="flex flex-col md:flex-row py-10">
          {issueData !== undefined &&
            issueData.prevPage &&
            issueData.prevPage.default && (
              <div className="hidden items-center px-10 max-w-[325px] flex-1 mr-14 mt-10 2xl:flex">
                <div>
                  <div className="relative">
                    <a href={`/pagina/${issueData.prevPage.docUid}`}>
                      <img
                        src={issueData.prevPage.small}
                        alt="Pagina Precedente Edizione Corriere Del Ticino"
                      />
                      <BackgroundOverlay opacity="bg-black/30" />
                    </a>
                  </div>
                  <div>
                    <div className="font-title text-3xl color-red font-bold mt-4">
                      {issueData.prevPage.pageNumber}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div
            className="relatve  flex flex-1 mx-auto px-20"
            id="get_img_paper"
          >
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <div className="flex md:-mr-24 mt-5 items-center ">
                  <div className="flex-row md:mr-20 relative">
                    <div className="flex items-center justify-between">
                      {edition !== undefined && edition.issue !== undefined && (
                        <div className="text-3xl md:text-4xl py-5 pt-0 text-black font-title font-bold w-full text-center uppercase">
                          Edizione del{" "}
                          <span className="text-color-red">
                            {formatDate(edition.issue, "api")}
                          </span>
                        </div>
                      )}
                    </div>
                    {issueData !== undefined && issueData.prevPage && (
                      <div className="hidden text-left absolute -left-24 2xl:flex h-full items-center">
                        <a href={`/pagina/${issueData.prevPage.docUid}`}>
                          <FaChevronLeft className="text-6xl -mt-28 text-color-red" />
                        </a>
                      </div>
                    )}
                    {issueData !== undefined && issueData.nextPage && (
                      <div className="hidden text-right  absolute -right-24 xl:flex h-full items-center">
                        <a href={`/pagina/${issueData.nextPage.docUid}`}>
                          <FaChevronRight className="text-6xl -mt-28 text-color-red" />
                        </a>
                      </div>
                    )}
                    <TransformComponent>
                      <div>
                        {issueData && issueData.pageImages && (
                          <img
                            ref={imageRef}
                            className="w-full md:max-w-[500px] mx-auto"
                            src={issueData.pageImages?.default}
                          />
                        )}
                      </div>
                    </TransformComponent>
                    {issueData !== undefined && issueData.pageNumber && (
                      <div className="font-title text-5xl color-red font-bold mt-4">
                        {issueData.pageNumber}
                      </div>
                    )}
                    <div className="hidden tools my-5 md:flex flex-row md:flex-col md:absolute md:-right-20 md:top-10 justify-center">
                      <button
                        onClick={() => downloadImg()}
                        className="icon-zoom mx-5 bg-white mb-2"
                      >
                        <FaFileDownload className="text-xl text-color-red" />
                      </button>
                      <button
                        onClick={() => zoomIn()}
                        className="icon-zoom  mx-5 bg-white mb-2"
                      >
                        <FaSearchPlus className="text-xl text-color-red" />
                      </button>

                      <button
                        onClick={() => zoomOut()}
                        className="icon-zoom mx-5 bg-white mb-2"
                      >
                        <FaSearchMinus className="text-xl text-color-red" />
                      </button>
                      <button
                        onClick={() => resetTransform()}
                        className="icon-zoom  mx-5 bg-white mb-2"
                      >
                        <FaUndo className="text-xl text-color-red" />
                      </button>
                    </div>
                  </div>
                  {/* <div className="mini-map flex items-center justify-content relative flex-col">
                    <div>
                      <div className="font-title text-black font-semibold mb-2 text-xl">
                        Preview
                      </div>
                      <MiniMap borderColor="#ca0000" width={200} height={800}>
                        <img
                          ref={imageRef}
                          className="h-full w-full"
                          src={`https://sec-archivio-public.s3.eu-central-1.amazonaws.com/default/${id}.png`}
                        />
                        <ImageOverlay />
                      </MiniMap>
                    </div>
                  </div> */}
                </div>
              )}
            </TransformWrapper>
          </div>
          {issueData !== undefined &&
            issueData.nextPage &&
            issueData.nextPage.default && (
              <div className="hidden xl:flex items-center px-10 max-w-[325px] flex-1 ml-14 mt-10">
                <div>
                  <div className="relative">
                    <a href={`/pagina/${issueData.nextPage.docUid}`}>
                      <img
                        src={issueData.nextPage.small}
                        alt="Pagina Precedente Edizione Corriere Del Ticino"
                      />
                      <BackgroundOverlay opacity="bg-black/30" />
                    </a>
                  </div>
                  <div className="font-title text-3xl color-red font-bold mt-4">
                    {issueData.nextPage.pageNumber}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {showPagination && (
        <div className="edition-carousel z-50 pb-5 md:mb-0 md:absolute md:left-0  md:top-0 bottom-0 left-0 right-0 md:right-auto overflow-x-scroll md:overflow-y-scroll pt-5 md:h-[calc(100%)] bg-gray-100">
          <div className="text-sm md:text-center pl-5 md:px-2 pb-5 font-title font-bold text-black leading-tight">
            SFOGLIA <br className="hidden md:block" /> L'EDIZIONE
            <span className="text-color-red pl-1">COMPLETA</span>
          </div>
          {isLoadingEdition && (
            <div>
              <FaSpinner className="text-4xl h-full flex justify-center items-center m-auto animate-spin  text-slate-800" />
            </div>
          )}
          {edition !== undefined && (
            <CarouselEditions editions={edition?.pages} current={id} />
          )}
        </div>
      )}
    </Wrapper>
  );
};

export default ViewPage;
