import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import getSwalCustomClasses from "./config/getSwalCustomClasses";
const MySwal = withReactContent(Swal);

export default function handleTimeout(error) {
  if (error.code === "ECONNABORTED") {
    MySwal.fire({
      customClass: getSwalCustomClasses,
      title: "Stiamo scrivendo la storia",
      text: "È in corso un aggiornamento dell’Archivio Storico. Torna a trovarci tra un po’ e sfoglia i nuovi anni disponibili.",
      icon: "info",
      confirmButtonText: "Ok",
    });
    return;
  }
}
