import { Routes, Route } from "react-router-dom";
import BachecaDeiRicordiPage from "./pages/bacheca-dei-ricordi";
import FaqPage from "./pages/faq";
import HomePage from "./pages/home";
import ListaRicordiEditionPage from "./pages/lista-ricordi-edition";
import NotFoundPage from "./pages/not-found";
import RicordoPage from "./pages/ricordo-page";
import SearchPage from "./pages/search";
import ViewPage from "./pages/view-page";

function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/bacheca-dei-ricordi" element={<BachecaDeiRicordiPage />} />
      <Route path="/bacheca-dei-ricordi/:id" element={<RicordoPage />} />
      <Route path="/lista-ricordi/:id" element={<ListaRicordiEditionPage />} />
      <Route path="/pagina/:id" element={<ViewPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
