import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/auth-context";

const Piano = () => {
  const [pianoUpdate, setPianoUpdate] = useState(false);
  const userCTX = useContext(UserContext);
  const {
    user, // {auth, products, revalidate }
    userPiano,
    userPianoAccesses,
  } = userCTX;

  useEffect(() => {
    /**
     * Config script for tinypass
     */
    const pianoCore = document.createElement("script");
    pianoCore.src = "https://cdn-eu.piano.io/api/tinypass.min.js";
    pianoCore.id = "piano-core-script";
    pianoCore.async = true;

    /**
     * Piano Config Script
     */
    const pianoConfig = document.createElement("script");
    pianoConfig.src = "/js/config-piano.js?v=00002";
    pianoConfig.async = true;
    pianoCore.addEventListener("load", function (event) {
      document.body.appendChild(pianoConfig);
      setPianoUpdate(true);
    });

    /**
     * Piano Core Script
     */
    if (!document.getElementById("piano-core-script")) {
      document.body.appendChild(pianoCore);
    }
  }, []);

  useEffect(() => {
    userCTX.getPianoUser();
  }, [pianoUpdate]);

  return <></>;
};
export default Piano;
