import { useState } from "react";

const InputCounter = ({ introUser }) => {
  const [counter, setCounter] = useState(0);

  return (
    <div className="relative">
      <input
        name="introUser"
        ref={introUser}
        id="intro-user"
        maxLength="80"
        placeholder="Dai un titolo al tuo ricordo"
        style={{ resize: "none" }}
        className="border w-full h-full p-2 focus:outline-none mb-8 text-sm"
        onChange={(e) => setCounter(e.target.value.length)}
      />
      <span className="font-title text-sm absolute bottom-2 right-0">
        Caratteri
        <span id="count-char" className="pl-2">
          {counter}
        </span>{" "}
        / 80
      </span>
    </div>
  );
};

export default InputCounter;
