const Footer = () => {
	return (
		<footer className="  border-t">
			<div className="pt-5 flex flex-col md:flex-row justify-between px-10 font-title ">
				<div className=" flex-1 px-10 md:border-r pl-0 border-b pb-5 md:border-b-0 md:pb-0">
					<div className="font-semibold pb-1">
						Società Editrice del Corriere del Ticino SA
					</div>
					<div>Via Industria</div>
					<div>6933 Muzzano (Lugano)</div>
					<div>Tel (+41) 91 960 31 31</div>
				</div>
				<div className=" flex-1 border-b pb-5 md:border-b-0 md:pb-0 md:border-r pt-5 md:pt-0 md:px-10">
					<div className="font-semibold pb-1">Link utili</div>
					<div>
						<a
							href="/bacheca-dei-ricordi"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Bacheca dei ricordi
						</a>
					</div>
					<div>
						<a
							href="https://www.cdt.ch/contatti"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Contatti
						</a>
					</div>
					<div>
						<a
							href="https://www.cdt.ch/abbonati"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Abbonamenti
						</a>
					</div>
					<div>
						<a
							href="/faq"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							FAQ
						</a>
					</div>
				</div>
				<div className="flex-1 pt-5 md:pt-0 md:px-10">
					<div className="font-semibold pb-1">Gruppo Corriere del Ticino</div>
					<div>
						<a
							href="https://www.cdt.ch/"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Corriere del Ticino
						</a>
					</div>
					<div>
						<a
							href="https://gruppocdt.ch/"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							La Fondazione
						</a>
					</div>

					<div>
						<a
							href="https://www.cdt.ch/condizioni-di-utilizzo"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Condizione di Utilizzo
						</a>
					</div>
					<div>
						<a
							href="https://www.cdt.ch/condizioni-di-vendita"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Condizione di Vendita
						</a>
					</div>
					<div>
						<a
							href="https://privacy.gruppocdt.ch/it/privacy-policy/web/cdt-archiviostorico"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Privacy Policy
						</a>
					</div>
					<div>
						<a
							href="https://privacy.gruppocdt.ch/it/cookie-policy/web/cdt-archiviostorico"
							target="_blank"
							className="hover:text-color-red hover:cursor-pointer text-black"
						>
							Cookie Policy
						</a>
					</div>
					<div>
						<a
							href="#"
							className="hover:text-color-red hover:cursor-pointer text-black iubenda-cs-preferences-link"
						>
							Le tue preferenze relative alla privacy
						</a>
					</div>
				</div>
			</div>
			<div></div>
			<div className="text-center text-xs border-t py-1 bg-color-red font-body mt-5">
				@cdt.ch - Riproduzione riservata
			</div>
		</footer>
	);
};

export default Footer;
