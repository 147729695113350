import { createContext, useState, useEffect } from "react";

const UserContext = createContext({
  user: null, // {auth, products, revalidate }
  userPiano: null,
  userPianoAccesses: [],
  isValidPianoUser: function () {},
  getPianoUser: function () {},
  deletePianoSession: function () {},
});

export function UserContextProvider(props) {
  /**
   * This function manage the changes on the user state
   */

  const [cookie, setCookie] = useState(null); // used for normal user cookie based
  const [userPianoState, setUserPiano] = useState(null); // used for piano login
  const [userPianoAccesses, setUserPianoAccesses] = useState([]);

  useEffect(() => {
    if (userPianoState !== null) {
      tp.api.callApi("/access/list", {}, function (response) {
        setUserPianoAccesses(response.data || []);
      });
    } else {
      setUserPianoAccesses([]);
    }
  }, [userPianoState]);

  /**
   * Return if logged user data
   * @returns Object || null
   */
  function getPianoUserHandler(user) {
    if (typeof tp !== "undefined") {
      setUserPiano(user || tp.pianoId.getUser());
    }
  }

  /**
   * Return if logged true || null
   * @returns Object || null
   */
  function getIsValidUser() {
    let isValid = false;
    if (typeof tp !== "undefined") {
      isValid = tp.pianoId.isUserValid();
    }
    return isValid;
  }

  /**
   * Return if logged true || null
   * @returns Object || null
   */
  async function deletePianoSessionHandler() {
    let isValid = true;
    if (typeof tp === "undefined") {
      return;
    }
    isValid = await tp.pianoId.logout();
    setUserPiano(null);
    return isValid;
  }

  const context = {
    user: cookie,
    userPiano: userPianoState,
    userPianoAccesses: userPianoAccesses,
    getPianoUser: getPianoUserHandler,
    isValidPianoUser: getIsValidUser,
    deletePianoSession: deletePianoSessionHandler,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
