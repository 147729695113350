import Wrapper from "../comps/common/wrapper";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Overlay from "../comps/common/overlay";
import requestManager from "../utils/requests/requestManager";
import PreviewPage from "../comps/preview-page";
import SearchBlock from "../comps/search-block/search-block";
import goToResult from "../utils/goToResults";
import replaceQueryParam from "../utils/replaceQueryParam";
import ReactPaginate from "react-paginate";
import toggleOverflowBody from "../utils/toggleOverflowBody";
import handleTimeout from "../utils/handleTimeout";
import formatDate from "../utils/formatDate";
import axios from "axios";
import { Helmet } from "react-helmet";

const SearchPage = () => {
  const [isLoading, setIsLoading] = useState(false); // used for loading status of the search
  const [results, setResults] = useState([]); // used for results of the search, default empty array
  const [haveSearched, setHaveSearched] = useState(false); // used for results of the search, default empty array
  const [stringDateApi, setStringDateApi] = useState(); // string used for api of the memories related to edition, for every edition get a date that ll be append in stirng to make a single request
  const [pageCount, setPageCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams(); // used to get all params from react router dom
  const location = useLocation();
  const navigate = useNavigate();

  const qs = searchParams.get("qs"); //  query string of the search
  const type = searchParams.get("results"); // type of search : STRICT or ANY (results)
  const order = searchParams.get("order"); // order of search : DESC or ASC
  const date = searchParams.get("date"); // OPTIONAL! Can be a single date (day) or range (from - to)
  const page = searchParams.get("page"); // OPTIONAL! if there goes as ?page=x
  /**
   * When you come to search page start search based on url params
   */
  useEffect(() => {
    const fetchData = async () => {
      // get the data from the api
      setIsLoading(true);
      toggleOverflowBody();
      const dataResponse = await requestManager(qs, type, order, date, page);
      console.log(dataResponse);
      console.log("******");
      /**
       * For every result take his date and put in a string in order to ask if there is any memory for this specific
       * edition
       */
      let stringDate = "";
      if (
        dataResponse.data?.data?.results !== undefined &&
        dataResponse.data?.data?.results.length >= 1
      ) {
        dataResponse.data.data.results.map((record, index) => {
          let convertedDate = formatDate(record.issue, "api");
          stringDate += convertedDate;
          if (index + 1 < dataResponse.data?.data?.results.length) {
            stringDate += ",";
          }
        });
        setStringDateApi(stringDate);
      }
      if (dataResponse.data?.data) {
        if (dataResponse.data?.data?.results !== undefined) {
          setResults(dataResponse.data?.data?.results);
        } else {
          setResults([]);
        }
        if (dataResponse.data?.data?.maxPages !== undefined) {
          setPageCount(dataResponse.data?.data?.maxPages);
        } else {
          setPageCount();
        }
      }
      toggleOverflowBody();
      setIsLoading(false);
      setHaveSearched(true);
      goToResult();
      return dataResponse;
    };

    // se refreshi devono comunque esserci i valori base, se non ci sono non ti faccio partire la ricerca inutilmente
    if ((qs || date) && order && results) {
      // call the function
      fetchData()
        // make sure to catch any error
        .catch((error) => {
          setIsLoading(false);
          handleTimeout(error);
          toggleOverflowBody();
          console.log(error);
        });
    }
  }, [qs, type, order, date, page]);

  useEffect(() => {
    /**
     * If string date api is changed means we have request to do in order to check wich edition has a memory inside!
     */
    if (stringDateApi) {
      axios({
        method: "get",
        url: `https://archivmanager.cdt.ch/api/remembers/list?editions=${stringDateApi}`,
        timeout: 15000,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.data) {
              const data = response.data.data;
              const allMemories = Object.keys(data);
              /**
               * Take all memories from api and cicle them
               */
              allMemories.map((memoryData) => {
                /**
                 * Take all elements found in list so if same edition is multiple time in list u cicle in them
                 */
                const editionBoxEl = document.querySelectorAll(
                  `#page-results [data-id="${memoryData}"]`
                );
                /**
                 * If there is any element cicle it
                 */
                if (editionBoxEl !== undefined) {
                  Object.keys(editionBoxEl).map((indexDivElement) => {
                    /**
                     * Take the button element empty inside the element (later u ll append here the button)
                     */
                    const buttonElement =
                      editionBoxEl[indexDivElement].querySelector(
                        "#read-memory-btn"
                      );
                    buttonElement.innerHTML = "";
                    /**
                     * Create the style of the button
                     */
                    let myElement = document.createElement("div");
                    myElement.classList.add(
                      "bg-gray-cdt",
                      "text-black",
                      "font-title",
                      "font-bold",
                      "inline-block",
                      "mr-5",
                      "cursor-pointer",
                      "text-sm",
                      "ml-3"
                    );
                    /**
                     * Create the ancor and append it to the button
                     */
                    let myElementHref = document.createElement("a");
                    myElementHref.classList.add("py-2", "px-5", "inline-block");
                    myElement.appendChild(myElementHref);
                    /**
                     * If edition has MORE than 1 memory put different link and go to edition memory page
                     */
                    if (
                      data[memoryData].items !== undefined &&
                      data[memoryData].items.length >= 2
                    ) {
                      myElementHref.text = "Leggi i ricordi";
                      myElementHref.href = `/lista-ricordi/${memoryData}`;
                      buttonElement.appendChild(myElement);
                    } else {
                      /**
                       * If only 1 memory then goes straight to page memory
                       */
                      myElementHref.text = "Leggi il ricordo";
                      myElementHref.href = `/bacheca-dei-ricordi/${data[memoryData].items[0].id}`;
                      buttonElement.appendChild(myElement);
                    }
                  });
                }
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [stringDateApi]);

  const handlePageClick = (userClick) => {
    const clickedPage = userClick.selected + 1;

    let myURL = location.search;

    if (page === null) {
      const goToURL = myURL + `&page=${clickedPage}`;
      navigate(goToURL);
      return;
    }
    myURL = replaceQueryParam("page", clickedPage, myURL);
    navigate(myURL);
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Archivio Storico CdT - Ricerca</title>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="noindex,nofollow" />
        <meta
          name="description"
          content="Scopri le passate edizioni del Corriere del Ticino. Tante funzionalità per vivere il passato, condividere i tuoi ricordi e immaginare il futuro."
        />
      </Helmet>
      {isLoading && <Overlay />}
      <SearchBlock setResults={setResults} />

      <div id="page-results">
        {haveSearched && (
          <>
            <div className="font-title font-bold text-2xl pl-10 py-6 bg-white border-b">
              Ecco cosa abbiamo trovato
            </div>
            {results !== undefined && results.length === 0 && (
              <div className="body-info text-center py-36 text-3xl">
                Non è stato trovato alcun risultato
              </div>
            )}
            <div className="mt-2">
              {results !== undefined &&
                results !== null &&
                results.map((result, index) => {
                  return (
                    <PreviewPage
                      type={type}
                      dataPage={result}
                      query={qs}
                      key={result.issue + `-${index}`}
                      index={index}
                    />
                  );
                })}
            </div>

            {results !== undefined &&
              results.length !== 0 &&
              pageCount !== undefined && (
                <div className="pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="Avanti"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    forcePage={page !== null ? parseInt(page - 1) : 0}
                    previousLabel="Indietro"
                    renderOnZeroPageCount={null}
                  />
                </div>
              )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default SearchPage;
