export default function checkDate(date) {
  let value = false;
  let timestamp = Date.parse(date);
  if (!isNaN(timestamp)) {
    /**
     * If date is valid push into array
     */
    value = true;
  }
  return value;
}
