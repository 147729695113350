import { useState, useEffect, useRef } from "react";
import DatePicker from "./date-picker";
import formatDate from "../utils/formatDate";
import { useNavigate, useSearchParams } from "react-router-dom"; // for search history
import getSwal from "../utils/getSwal";
import InputMask from "./inputmask";
import formatAllDateIsoStandard from "../utils/formatAllDateIsoStandard";
import checkDate from "../utils/checkDate";
import SelectDate from "./datepicker/select-date";
import axios from "axios";

const SearchContainer = ({ setResults }) => {
  const [toggleNav, setToggleNav] = useState(0); // toggle used to nav in search tab container = 0 = SEARCH FOR STORIES ||| 1 = SEARCH FOR ISSUES (EDIZIONI)
  const [searchType, setSearchType] = useState(true); // 0 = RICERCA PARZIALE  ||| 1 = RICERCA ESATTA
  const [filterType, setFilterType] = useState(true);
  const [datePicker, setDatePicker] = useState([]); // updating the status of the search datepicker, if user change or date not valid not sent to API
  // Default status for result research base is false after the first search pop out
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // used to get all params from react router dom
  const datePickerRefFrom = useRef(null); // used for inputMask data
  const datePickerRefTo = useRef(null); // used for inputMask data
  const datePickerRefToSet = useRef(null); // used for inputMask data

  const qs = searchParams.get("qs"); //  query string of the search
  const type = searchParams.get("results"); // type of search : STRICT or ANY (results)
  const order = searchParams.get("order"); // order of search : DESC or ASC
  const date = searchParams.get("date"); // OPTIONAL! Can be a single date (day) or range (from - to)

  const [years, setYears] = useState(); // Year for React select : base undefined after request fill
  const [isLoading, setIsLoading] = useState(false); // For react select year data: is still loading the request?
  const [dataState, setData] = useState(); // data usedToManage state of select date comps

  useEffect(() => {
    // every time change toggle search option reset to [] datepicker
    if (toggleNav === 0) {
      document.querySelector(".date-label").classList.remove("active");
      document.querySelector(".text-label").classList.add("active");
    } else {
      document.querySelector(".text-label").classList.remove("active");
      document.querySelector(".date-label").classList.add("active");
    }
  }, [toggleNav]);

  // function to get state of the input mask!
  const getInputDataStateFrom = () => {
    const dataState = datePickerRefFrom.current.getInputMaskDataFrom();
    return dataState;
  };

  // function to get state of the input mask!
  const getInputDataStateTo = () => {
    const dataState = datePickerRefTo.current.getInputMaskDataTo();
    return dataState;
  };

  // function to get state of the input mask!
  const setInputDataStateTo = () => {
    const setDataStateTo = datePickerRefToSet.current.setInputMaskDataTo();
    return setDataStateTo;
  };

  useEffect(() => {
    if (qs !== null) {
      inputRef.current.value = qs;
    }

    if (type !== null) {
      // check if params set by user are valid else if take default param
      if (type === "any") {
        setSearchType(true);
      } else {
        setSearchType(false);
      }
    }

    if (order !== null) {
      // check if params set by user are valid else if take default param
      if (order === "desc") {
        setFilterType(true);
      } else {
        setFilterType(false);
      }
    }

    if (date !== null) {
      const myDates = date.split(";");
      let myValidDates = [];
      myDates.map((singleDate, index) => {
        if (index >= 2) {
          return;
        }
        let timestamp = Date.parse(singleDate);
        if (!isNaN(timestamp)) {
          /**
           * If date is valid push into array
           */
          myValidDates.push(formatDate(singleDate, "original"));
        }
      });
      setDatePicker(myValidDates);
      if (qs === null) {
        setToggleNav(1);
      }
    }
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    /**
     * Default value for a basic search,
     * RESULTS TYPE : ANY
     * FILTER : DESC
     * DATE : NO-DATE
     */
    let resultsType = "any"; // can be any or strict: ANY -> any resoult api can find -> STRICT : only exact word or sentence
    let queryFilter = "desc"; // DESC -> From newest to oldest record || ASC -> from oldest to newest record
    let myFullURL = `/search?`;
    let fromDate;
    let toDate;
    let isValidDateFrom = false;

    if (inputRef.current === null && !dataState) {
      getSwal(
        "Errore di ricerca",
        "Non puoi fare una ricerca per data senza prima selezionare alcuna data",
        "error"
      );
      return;
    }

    /**
     * Check if search text is an empty string or empty value, if is block search process
     */
    if (inputRef.current !== null) {
      const checkSpaces = inputRef.current.value.replace(/\s/g, "");
      if (checkSpaces === "") {
        getSwal(
          "Errore di ricerca",
          "Non puoi fare una ricerca per testo senza inserire alcuna parola o frase",
          "error"
        );
        return;
      }
      myFullURL += `qs=${inputRef.current.value}&`;
    }

    if (searchType === false) {
      resultsType = "strict";
    }

    if (filterType === false) {
      queryFilter = "asc";
    }

    myFullURL += `results=${resultsType}&order=${queryFilter}`;

    /**
     * if there is any data check if valid
     */
    if (dataState) {
      /**
       * Check if from is there and all his parameters for data (day month year)
       */
      if (dataState["from"]) {
        if (
          dataState["from"].day &&
          dataState["from"].month &&
          dataState["from"].year
        ) {
          fromDate =
            dataState["from"].year +
            "-" +
            dataState["from"].month +
            "-" +
            dataState["from"].day;

          /**
           * Una volta composta la data controlliamo se valida e la aggiungiamo nelle API
           */
          const isoDateFrom = formatAllDateIsoStandard(fromDate, "dash"); // get data iso standard from state
          const isValidDate = checkDate(isoDateFrom); // check if valid date or not (return true or false)
          if (isValidDate) {
            /**
             * se e valida la usiamo nelle api
             */
            isValidDateFrom = true;
            myFullURL += `&date=${fromDate}`;
          }
        }
      }

      // Doing Same Thing with to date
      if (dataState["to"]) {
        if (
          dataState["to"].day &&
          dataState["to"].month &&
          dataState["to"].year
        ) {
          toDate =
            dataState["to"].year +
            "-" +
            dataState["to"].month +
            "-" +
            dataState["to"].day;
          /**
           * Check if too valid date and < of "FROM DATE if so insert"
           */
          const isoDateTo = formatAllDateIsoStandard(toDate, "dash"); // get data iso standard from state
          const isValidDateTo = checkDate(isoDateTo); // check if valid date or not (return true or false)
          const isoDateFrom = formatAllDateIsoStandard(fromDate, "dash");

          if (isValidDateTo && isoDateFrom < isoDateTo) {
            /**
             * se e valida la usiamo nelle api
             */
            myFullURL += `;${toDate}`;
          }
        }
      }
    }

    if (window.location.href === window.location.origin + myFullURL) {
      window.location.reload();
    }

    navigate(myFullURL);
    return;
  };

  const handleToggleNav = (param) => {
    setToggleNav(param);
    setDatePicker([]);
  };

  /**
   * UseEffect: used to retrieve Year available for scans
   */
  useEffect(() => {
    setIsLoading(true);
    /**
     * Get Status of Current Generated ARTICLES range of years
     */
    const api_base_url = process.env.REACT_APP_API_URL;
    axios
      .get(`${api_base_url + "/v2/status"}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data?.completion) {
            if (response.data.completion.yearsDetail) {
              const yearData = [];
              let yearOptions = [];

              response.data.completion.yearsDetail.map((yearObj) => {
                yearData.push(yearObj.year);
              });
              for (let year = 1891; year <= 2022; year++) {
                /**
                 * Se l'anno che viene passato c'e da api allora includilo nella ricerca else, mettilo come disabled
                 */
                if (yearData.includes(year.toString())) {
                  yearOptions.push({
                    value: year.toString(),
                    label: year.toString(),
                  });
                } else {
                  yearOptions.push({
                    value: year.toString(),
                    label: year.toString(),
                    isDisabled: true,
                  });
                }
              }

              setYears(yearOptions);
              setIsLoading(false);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const manageState = (subject, typeDay, value, valueData) => {
    let oppositeSubject = "";
    oppositeSubject = subject == "from" ? "to" : "from";
    let dataOBJ = {};
    if (!valueData) {
      dataOBJ[subject] = {
        [typeDay]: value,
      };
      setData(dataOBJ);
    } else {
      /**
       * DataGia Definita
       */
      if (valueData[oppositeSubject]) {
        dataOBJ[subject] = {
          ...valueData[subject],
          [typeDay]: value,
        };
        setData({
          [oppositeSubject]: {
            ...valueData[oppositeSubject],
          },
          ...dataOBJ,
        });
      } else {
        /**
         * Subject already existent so add to obj new param
         */
        dataOBJ[subject] = {
          ...valueData[subject],
          [typeDay]: value,
        };
        setData({
          ...dataOBJ,
        });
      }
    }
  };

  return (
    <div className="search-container md:max-w-1/2 mx-auto  mt-24">
      <div className="search-wall bg-white  px-10 py-10  search-box  md:mx-auto relative border-t">
        <div className="flex search-tab absolute tab-box left-0 bg-white  font-light  font-title">
          <div
            onClick={() => handleToggleNav(0)}
            className="text-label cursor-pointer inline-block px-6 border-r py-3 min-w-[100px] text-center  bg-gray-100"
          >
            Testo
          </div>
          <div
            className="date-label cursor-pointer  inline-block px-6 py-3 min-w-[100px] text-center"
            onClick={() => handleToggleNav(1)}
          >
            Data
          </div>
        </div>
        {toggleNav === 0 ? (
          <div className="box-container">
            <div className="py-2 ">
              <div className="text-black font-title font-semibold text-lg">
                Cosa stai cercando?
              </div>
              <form onSubmit={handleSearch} className="w-full">
                <input
                  type="text"
                  placeholder="Inserisci qui la parola o la frase di tuo interesse"
                  className="focus:outline-none p-2 border w-full"
                  ref={inputRef}
                />
                <div className="pt-2 text-gray-500 text-sm font-light pb-2">
                  <div className="pb-2 pt-2">Tipo di ricerca</div>
                  <div className="flex flex-col md:flex-row">
                    <div className="flex items-center pl-4 border checkbox-container mb-4 md:mb-0">
                      <input
                        id="bordered-radio-1"
                        type="radio"
                        value=""
                        name="bordered-radio-search"
                        className="w-4 h-4 focus:outline-none  "
                        checked={searchType === true}
                        onChange={() => setSearchType((prev) => !prev)}
                      />
                      <label
                        htmlFor="bordered-radio-1"
                        className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 color-black"
                      >
                        Una o più parole
                      </label>
                    </div>
                    <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700 checkbox-container">
                      <input
                        id="bordered-radio-2"
                        type="radio"
                        value=""
                        name="bordered-radio-search"
                        className="w-4 h-4 "
                        checked={searchType === false}
                        onChange={() => setSearchType((prev) => !prev)}
                      />
                      <label
                        htmlFor="bordered-radio-2"
                        className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 color-black"
                      >
                        Frase esatta
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pt-2 text-gray-500 text-sm font-light pb-2  ">
                  <div className="pb-2">Ordina risultati</div>
                  <div className="flex flex-col md:flex-row">
                    <div className="flex items-center pl-4  border  checkbox-container  mb-4 md:mb-0">
                      <input
                        id="bordered-radio-3"
                        type="radio"
                        value=""
                        name="bordered-radio-filter"
                        className="w-4 h-4 focus:outline-none  "
                        checked={filterType === true}
                        onChange={() => setFilterType((prev) => !prev)}
                      />
                      <label
                        htmlFor="bordered-radio-3"
                        className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 color-black"
                      >
                        Dal più nuovo
                      </label>
                    </div>
                    <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700 checkbox-container">
                      <input
                        id="bordered-radio-4"
                        type="radio"
                        value=""
                        name="bordered-radio-filter"
                        className="w-4 h-4 "
                        checked={filterType === false}
                        onChange={() => setFilterType((prev) => !prev)}
                      />
                      <label
                        htmlFor="bordered-radio-4"
                        className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 color-black"
                      >
                        Dal più vecchio
                      </label>
                    </div>
                  </div>
                </div>

                {years && (
                  <>
                    <div className="text-sm font-light flex items-center text-gray-500 cursor-pointer pt-2">
                      Ricerca per data
                    </div>
                    <div className="mt-2 date-range-home  text-datepicker">
                      <div className="flex flex-col lg:flex-row">
                        <div className="flex-1">
                          <div>Da</div>
                          <div className="flex">
                            <div className="flex-1">
                              <SelectDate
                                isLoading={isLoading}
                                years={years}
                                subject="from"
                                manageState={manageState}
                                dataState={dataState}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-1 mt-5 lg:mt-0 lg:ml-5">
                          <div>A</div>
                          <div className="flex">
                            <div className="flex-1">
                              <SelectDate
                                isLoading={isLoading}
                                years={years}
                                subject="to"
                                manageState={manageState}
                                dataState={dataState}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="flex justify-center items-center cursor-pointer bg-color-red  mt-7 max-w-[230px] mx-auto">
                  <button className="px-10  py-2 w-full h-full">CERCA</button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="box-container">
            <form onSubmit={handleSearch} className="w-full">
              <div className="text-black font-title font-semibold text-lg">
                Quale data stai cercando?
                <div>
                  Seleziona la data o le date per cui vuoi vedere il Corriere
                  del Ticino
                </div>
              </div>
              <div className="mt-3 date-range-home ">
                <div className="flex flex-col lg:flex-row">
                  <div className="flex-1">
                    <div>Da</div>
                    <div className="flex">
                      <div className="flex-1">
                        <SelectDate
                          isLoading={isLoading}
                          years={years}
                          subject="from"
                          manageState={manageState}
                          dataState={dataState}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 mt-5 lg:mt-0 lg:ml-5">
                    <div>A</div>
                    <div className="flex">
                      <div className="flex-1">
                        <SelectDate
                          isLoading={isLoading}
                          years={years}
                          subject="to"
                          manageState={manageState}
                          dataState={dataState}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2 text-gray-500 text-sm font-light pb-2">
                <div className="pb-2 mt-3 md:mt-0">Ordina risultati</div>
                <div className="flex flex-col md:flex-row">
                  <div className="flex items-center pl-4 mb-5 md:mb-0   border  checkbox-container">
                    <input
                      id="bordered-radio-3"
                      type="radio"
                      value=""
                      name="bordered-radio-filter"
                      className="w-4 h-4 focus:outline-none  "
                      checked={filterType === true}
                      onChange={() => setFilterType((prev) => !prev)}
                    />
                    <label
                      htmlFor="bordered-radio-3"
                      className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 color-black"
                    >
                      Dal più nuovo
                    </label>
                  </div>
                  <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700 checkbox-container">
                    <input
                      id="bordered-radio-4"
                      type="radio"
                      value=""
                      name="bordered-radio-filter"
                      className="w-4 h-4 "
                      checked={filterType === false}
                      onChange={() => setFilterType((prev) => !prev)}
                    />
                    <label
                      htmlFor="bordered-radio-4"
                      className="w-full py-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 color-black"
                    >
                      Dal più vecchio
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center cursor-pointer bg-color-red mt-7 max-w-[230px] mx-auto">
                <button className="px-10 py-2 h-full w-full">CERCA</button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchContainer;
