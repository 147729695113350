import Footer from "./footer";
import Header from "./header";
import Piano from "./piano";

const Wrapper = ({ children }) => {
  return (
    <div>
      {/* <Piano /> */}
      <Header />
      <main className="relative">{children}</main>
      <Footer />
    </div>
  );
};

export default Wrapper;
