import formatDate from "../utils/formatDate";
import { useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormWrapper from "./memory-form/form-wrapper";
import InputCounter from "./memory-form/input-counter";
import TextAreaCounter from "./memory-form/textarea-counter";
import MyDropzone from "./memory-form/file-dropzone";
import getSwalCustomClasses from "../utils/config/getSwalCustomClasses";
import getElementInputName from "../utils/getElementInputName";
import RadioArea from "./memory-form/radio-area";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PreviewPage = ({ dataPage, query, type }) => {
  const {
    pageImages,
    page,
    titleOverview,
    issueUid,
    issue,
    fragments,
    property,
    docUid,
    uid,
  } = dataPage;

  const MySwal = withReactContent(Swal);

  // Gestisci i file che l'utente può caricare
  const filesStateRef = useRef();
  const radioStateRef = useRef();

  const getFilesState = () => {
    const filesState = filesStateRef.current.getAllFiles();
    return filesState;
  };

  const getRadioState = () => {
    const radioState = radioStateRef.current.getRadio();
    return radioState;
  };

  /**
   * Data request for user in order to subscribe a new memory
   */
  const nameUser = useRef(undefined);
  const lastNameUser = useRef(undefined);
  const mailUser = useRef(undefined);
  const residenceUser = useRef(undefined);
  const introUser = useRef(undefined);
  const descriptionUser = useRef(undefined);
  const checkboxPolicy = useRef(undefined);
  const data = new FormData(); //FORM DATA

  const FormModal = () => {
    return (
      <FormWrapper>
        <div className="flex flex-col md:flex-row">
          <div className="w-full">
            <input
              type="text"
              name="nameUser"
              placeholder="Inserisci il tuo nome"
              ref={nameUser}
              className="w-full p-2  mb-5 border focus:outline-none flex-1 text-sm"
              id="err-msg-name"
            />
          </div>
          <div className="w-full  md:ml-5">
            <input
              type="text"
              name="lastNameUser"
              ref={lastNameUser}
              placeholder="Inserisci il tuo cognome"
              className="w-full p-2  mb-5 border focus:outline-none flex-1 text-sm"
              id="err-msg-lastname"
            />
          </div>
        </div>
        <div>
          <input
            type="text"
            name="mailUser"
            ref={mailUser}
            placeholder="Inserisci il tuo indirizzo email"
            className="w-full p-2 mb-5 border focus:outline-none text-sm"
            id="err-msg-mail"
          />
        </div>
        <div>
          <input
            type="text"
            name=""
            ref={residenceUser}
            placeholder="Inserisci la tua località di residenza"
            className="w-full p-2  mb-5 border focus:outline-none text-sm"
            id="err-msg-residence"
          />
        </div>
        <InputCounter introUser={introUser} />
        <div>
          <TextAreaCounter descriptionUser={descriptionUser} />
        </div>
        <div>
          <MyDropzone ref={filesStateRef} />
        </div>
        <div
          id="error-dropzone"
          className="text-left text-xs text-color-red pt-2"
        ></div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="modal-checkbox"
            ref={checkboxPolicy}
            className="max-w-[15px] h-8 w-8 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
          />
          <label className="ml-2 text-sm font-title" htmlFor="modal-checkbox">
            <span>Ho preso visione </span>
            <a
              className="text-color-red hover:text-colo-red "
              href="https://privacy.gruppocdt.ch/it/privacy-policy/web/cdt-archiviostorico"
              target="_blank"
            >
              dell'Informativa Privacy
            </a>
          </label>
        </div>
        <div className="border-t py-3 my-3 border-b pb-4">
          <div className="font-light font-title text-sm text-left">
            Desidero ricevere la newsletter promozionale (finalità di marketing
            diretto, vedi Informativa Privacy)
          </div>
          <div className="flex items-center pt-2">
            <RadioArea radioStateRef={radioStateRef} />
          </div>
        </div>
        <div
          id="err-policy"
          className="text-left text-xs text-color-red pt-2"
        ></div>
        <div
          id="err-msg"
          className="text-left text-xs text-color-red pt-2"
        ></div>
      </FormWrapper>
    );
  };

  const handleNewMemory = async () => {
    MySwal.fire({
      title: `Inserisci <span className="text-color-red">il tuo ricordo</span>`,
      html: <FormModal />,
      confirmButtonText: "INVIA IL RICORDO",
      showCancelButton: true,
      cancelButtonText: "ANNULLA",
      confirmButtonColor: "#ca0000",
      customClass: getSwalCustomClasses,
      allowOutsideClick: false,
      preConfirm: () => {
        let returnValue = true;
        let errorMsgShow = false;

        const errorMsgEl = document.getElementById("err-msg");
        const errorPolicyEl = document.getElementById("err-policy");

        /**
         * Check if privacy policy is checked if not print error msg
         */
        if (checkboxPolicy.current.checked === false) {
          errorPolicyEl.innerHTML = "*Non hai accettato la Privacy Policy";
          returnValue = false;
        } else {
          errorPolicyEl.innerHTML = "";
        }

        /**
         * Take all elements input ref
         */
        const allElementsRef = [
          {
            ref: nameUser,
            name: "name",
          },
          {
            ref: lastNameUser,
            name: "lastname",
          },
          {
            ref: residenceUser,
            name: "residence",
          },
          {
            ref: descriptionUser,
            name: "description",
          },
          {
            ref: mailUser,
            name: "mail",
          },
          {
            ref: introUser,
            name: "title",
          },
        ];

        allElementsRef.map((elObj) => {
          /**
           * For every input ref check if current value is empty
           */
          const errorMsgEl = document.getElementById(
            `${getElementInputName(elObj.name)}`
          );
          if (elObj.ref.current.value === "") {
            returnValue = false;
            errorMsgShow = true;
            errorMsgEl.classList.add("border-color-red");
          } else {
            errorMsgEl.classList.remove("border-color-red");
          }
        });

        if (errorMsgShow === true) {
          errorMsgEl.innerHTML =
            "*Non hai compilato i campi obbligatori del formulario";
        }

        return returnValue;
      },
    }).then((e) => {
      /**
       * IF ANY of those inputs is empty block request
       */

      if (e.isDismissed) {
        return;
      }

      const allFiles = getFilesState();

      /**
       * If there are any file in the select box map them and put in form data!
       */
      if (allFiles.length >= 1) {
        allFiles.map((myFile) => {
          data.append(`files[]`, myFile, myFile.name);
        });
      }

      data.append("intro", introUser.current.value);
      data.append("author_name", nameUser.current.value);
      data.append("city", residenceUser.current.value);
      data.append("description", descriptionUser.current.value);
      data.append("author_email", mailUser.current.value);
      data.append("author_surname", lastNameUser.current.value);
      // Take issue data and format for api correct
      data.append("edition_date", formatDate(issue, "api"));

      if (getRadioState() === true) {
        data.append("promotional", "y");
      }

      var config = {
        method: "post",
        url: "https://archivmanager.cdt.ch/api/remember/save",
        timeout: 15000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          MySwal.fire({
            customClass: getSwalCustomClasses,
            title: "Inviato con successo",
            text: "Hai inviato il tuo ricordo con successo",
            icon: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch(function (error) {
          MySwal.fire({
            title: "Inviato non riuscito",
            text: "Dati inseriti non validi",
            icon: "error",
            confirmButtonText: "Ok",
            customClass: getSwalCustomClasses,
          });
        });
    });
  };

  /**
   * Per ogni edizionee costruire una stringa da passare in param come una SQUENZA di id per i fragments ricercati
   * cosi quando entri sulla pagina non solo hai la preview della immagine ma hai anche tutte le parti evidenziate che cercavi
   * ? : come fare le altre parti "cliccabili o meno del articolo?" come faccio a capire se un articolo o un "fragment" ha dei dati in + o meno?
   */

  return (
    <div
      className="preview-page border bg-white mx-10 p-5 py-5 my-6 flex"
      data-id={formatDate(issue, "api")}
    >
      <div className="flex flex-col flex-1 relative">
        <div className="font-title font-semibold text-2xl md:text-3xl pb-5">
          <a
            href={`/pagina/${
              docUid ? docUid : uid + "_001"
            }?query=${query}?glue=${type}`}
            className="hover:underline font-title w-full inline-block"
          >
            <span className="pl-2 w-full inline-block">
              {query
                ? `Risultato nell'Edizione del ${formatDate(issue, "api")}`
                : `Risultato nell'Edizione del  ${formatDate(issue, "api")}`}
            </span>
          </a>
        </div>
        <div className="flex min-h-[200px] flex-col md:flex-row">
          <div className="md:max-w-[150px]">
            <a
              href={`/pagina/${docUid ? docUid : uid + "_001"}?query=${query}`}
              className="hover:underline"
            >
              <LazyLoadImage
                alt={issueUid}
                src={pageImages?.small}
                width={150}
                height={225}
                className="w-full lazy"
              />
            </a>
          </div>
          <div className=" text-lg pt-5 pl-0 md:pl-5">
            {query && (
              <div className="">
                <span className="font-bold pr-1">
                  Ecco cosa abbiamo trovato:{" "}
                </span>
                {titleOverview ? titleOverview.replace(/\\/g, "") : `${query}`}
              </div>
            )}

            {page ? (
              <div className="mb-2">
                <span className="font-bold pr-1">Pagina numero: </span> {page}
              </div>
            ) : (
              <></>
            )}
            <div>
              <div className="mb-5 xl:mb-0  bg-color-red  font-title font-bold inline-block mr-5 py-1 px-5 cursor-pointer text-sm">
                <a
                  className="text-white white"
                  href={`/pagina/${
                    docUid ? docUid : uid + "_001"
                  }?query=${query}`}
                >
                  Vai alla pagina di giornale
                </a>
              </div>
            </div>
            <div className="flex  xl:absolute  xl:bottom-0">
              <div
                onClick={() => handleNewMemory(issue)}
                className="bg-gray-cdt text-black font-title font-bold py-1 px-5 cursor-pointer text-sm flex items-center"
              >
                Inserisci il tuo ricordo
              </div>
              <div id="read-memory-btn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPage;
