import axios from "axios";
import getSwal from "../getSwal";
import handleTimeout from "../handleTimeout";
import axiosConfig from "../config/const";

const {
  axiosConfig: { timeout },
} = axiosConfig;

export default async function requestManager(qs, type, order, date, page) {
  /**
   * QS (string):  STRING
   * TYPE (string) : STRICT | ANY
   * ORDER (string): ASC | DESC
   * DATE (string)?: OPTIONAL ! SINGLE DATE (DAY) or FROM - TO (RANGE)
   */
  const api_base_url = process.env.REACT_APP_API_URL;
  let glue = "any"; // default value for TYPE
  let sort = "desc"; // default value for ORDER
  let filter = {}; // used for date filter, if date are selected added to request
  let pageSelected = 1; // default page if no pagination is found

  // check if params set by user are valid else if take default param
  if (
    type === "strict" ||
    type === "STRICT" ||
    type === "any" ||
    type === "ANY"
  ) {
    glue = type;
  }

  // check if params set by user are valid else if take default param
  if (
    order === "asc" ||
    order === "ASC" ||
    order === "desc" ||
    order === "DESC"
  ) {
    sort = type;
  }

  // if date value !== null check validity
  // validity format
  // check if from is BEFORE TO

  if (date) {
    const myDates = date.split(";");
    let validDate = true;

    myDates.map((singleDate) => {
      let timestamp = Date.parse(singleDate);
      if (isNaN(timestamp)) {
        /**
         * Not valid date throw error
         */
        validDate = false;
        getSwal(
          "Data non valida",
          "Sei pregato di inserire una data valida per la ricerca",
          "error"
        );
        return;
      }
    });

    if (validDate === false) {
      return;
    }

    switch (myDates.length) {
      case 1:
        filter = {
          day: myDates[0],
        };
        // setHistoryDatePicker(filter);
        break;
      case 2:
        filter = {
          from: myDates[0],
          to: myDates[1],
        };
        // setHistoryDatePicker(filter);
        break;
      default:
        filter = undefined;
        break;
    }
  }

  if (page) {
    pageSelected = page;
  }

  if (qs) {
    return axios
      .post(
        `${api_base_url + "/v2/search"}`,
        {
          terms: qs,
          glue: glue,
          property: "CDT",
          kind: "stories", // pages: ritorna pagine, stories : ritorna gli articoli
          resultFormat: {
            detail: "deep",
            page: pageSelected,
            offset: 15, // numero di risultati ritornati
            sort: order,
          },
          filter: filter,
        },
        {
          timeout: timeout,
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        handleTimeout(err);
        console.log(err);
      });
  }

  return axios
    .post(
      `${api_base_url + "/v2/seek"}`,
      {
        glue: "ANY", // any : prende ogni valore che si chiama cosi, strict: prende solo esattamente valore richiesto
        property: "CDT", // che altre property abbiamo?
        kind: "stories", // pages: ritorna pagine, stories : ritorna gli articoli
        ...filter,
        resultFormat: {
          detail: "deep",
          page: 0,
          offset: 15, // numero di risultati ritornati
          sort: order,
        },
      },
      {
        timeout: timeout,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      handleTimeout(err);
      console.log(err);
    });
}
