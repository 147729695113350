import enGB from "date-fns/locale/it-CH";

const Calendar = {
  sunday: "Do",
  monday: "Lu",
  tuesday: "Ma",
  wednesday: "Me",
  thursday: "Gi",
  friday: "Ve",
  saturday: "Sa",
  ok: "OK",
  today: "Oggi",
  yesterday: "Ieri",
  hours: "Ore",
  minutes: "Minuti",
  seconds: "Secondi",
  /**
   * Format of the string is based on Unicode Technical Standard #35:
   * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
   **/
  formattedMonthPattern: "MMM yyyy",
  formattedDayPattern: "dd MMM yyyy",
  dateLocale: enGB,
};

export const locale = {
  common: {
    loading: "Caricamento...",
    emptyMessage: "Nessun dato trovato",
  },
  Plaintext: {
    unfilled: "Non riempito",
    notSelected: "Non selezionato",
    notUploaded: "Non caricato",
  },
  Pagination: {
    more: "Di Più",
    prev: "Precedente",
    next: "Successivo",
    first: "Primo",
    last: "Ultimo",
    limit: "{0} / page",
    total: "Righe Totali: {0}",
    skip: "Vai alla {0}",
  },
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: "Ultimi 7 Giorni",
  },
  Picker: {
    noResultsText: "Nessun risultato trovato",
    placeholder: "Seleziona...",
    searchPlaceholder: "Cerca",
    checkAll: "Tutti",
  },
  InputPicker: {
    newItem: "Nuovo Oggetto",
    createOption: 'Crea un opzione "{0}"',
  },
  Uploader: {
    inited: "Iniziale",
    progress: "Sto Caricando...",
    error: "Errore",
    complete: "Finito",
    emptyFile: "Vuoto",
    upload: "Caricato",
  },
  CloseButton: {
    closeLabel: "Chiuso",
  },
  Breadcrumb: {
    expandText: "Mostra Esteso",
  },
  Toggle: {
    on: "Apri",
    off: "Chiudi",
  },
};
