const PreviewRicordo = ({ memory }) => {
  return (
    <div className="preview-ricordo mb-5 border-b pb-5 font-title">
      <a
        className="flex flex-col md:flex-row text-black hover:text-color-red hover:cursor-pointer hover:no-underline focus:no-underline focus:text-inherit"
        href={`/bacheca-dei-ricordi/${memory.id}`}
      >
        <div>
          <img
            src={memory.cover["512x512"]}
            alt={memory.intro}
            className="w-full md:max-w-[200px]"
          />
        </div>
        <div className="pt-5 md:pt-0 pl-0 md:px-5 relative flex-1">
          <div className="text-color-red text-3xl font-title font-bold">
            <span className="pr-3">{memory.edition_date?.split?.date},</span>
            <span>{memory.author}</span>
          </div>
          <div className="pt-5 text-lg font-normal">{memory.intro}</div>
          <div className="text-gray-500 font-light text-xs pt-5 md:pt-0 md:absolute md:bottom-0 md:right-0">
            Pubblicato il {memory.created_at.split?.date}
          </div>
        </div>
      </a>
    </div>
  );
};

export default PreviewRicordo;
