export default [
  {
    url: "https://www.cdt.ch/abbonati",
    name: "ABBONATI/ACCEDI",
    targetBlank: true,
  },
  {
    url: "/bacheca-dei-ricordi",
    name: "BACHECA DEI RICORDI",
    targetBlank: false,
  },
  {
    url: "https://www.cdt.ch/contatti",
    name: "CONTATTI",
    targetBlank: true,
  },
  {
    url: "/faq",
    name: "FAQ",
    targetBlank: false,
  },
];
