export default function formatDate(date, format) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;

  if (day.length < 2) day = "0" + day;

  switch (format) {
    case "original":
      return [year, month, day].join("-");
    case "formatted":
      return [day, month, year].join("-");
    case "api":
      return [day, month, year].join(".");
  }
}
