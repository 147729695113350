import { DateRangePicker, CustomProvider } from "rsuite";
import "rsuite/dist/rsuite.css";
import { locale } from "./datepicker/locale";
const DatePicker = ({ dateFunction, defaultDate }) => {
  return (
    <CustomProvider locale={locale}>
      <DateRangePicker
        defaultValue={
          defaultDate.length >= 1
            ? [new Date(defaultDate[0]), new Date(defaultDate[1])]
            : null
        }
        size="xs"
        placeholder="Seleziona la data o il periodo per cui vuoi vedere il Corriere del Ticino"
        appearance="subtle"
        character="   |   "
        format="dd-MM-aaaa"
        isoWeek={true}
        style={{ zIndex: 999 }}
        onChange={(e) => dateFunction(e)}
      />
    </CustomProvider>
  );
};

export default DatePicker;
