import { useEffect } from "react";

const IubendaInit = () => {
  useEffect(() => {
    /**
     * Config script for tinypass
     */
    const iubendaCore = document.createElement("script");
    iubendaCore.src = "/js/iubenda.js";
    iubendaCore.id = "iubenda-script";
    iubendaCore.async = true;

    /**
     * Piano Config Script
     */
    const iubendaConfig = document.createElement("script");
    iubendaConfig.src = "//cdn.iubenda.com/cs/tcf/stub-v2.js";
    iubendaConfig.async = true;

    const iubendaConfig2 = document.createElement("script");
	iubendaConfig2.src = "//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js";
    iubendaConfig2.async = true;

    const iubendaConfig3 = document.createElement("script");
	iubendaConfig3.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    iubendaConfig3.async = true;

    iubendaCore.addEventListener("load", function (event) {
      document.body.appendChild(iubendaConfig);
      document.body.appendChild(iubendaConfig2);
      document.body.appendChild(iubendaConfig3);
    });

    /**
     * Iubenda Core Script
     */
    if (!document.getElementById("iubenda-script")) {
      document.body.appendChild(iubendaCore);
    }
  }, []);
  return <></>;
};

export default IubendaInit;
