import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import getSwalCustomClasses from "./config/getSwalCustomClasses";

export default function getSwal(title, text, type) {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    title: title,
    text: text,
    icon: type,
    confirmButtonText: "Ok",
    customClass: getSwalCustomClasses,
  });
}
