import { useState } from "react";
import data from "../../utils/config/const";
import Select from "react-select";
import classNames from "classnames";

export default function SelectDate({
  isLoading,
  years,
  subject,
  manageState,
  dataState,
}) {
  const [days, setDays] = useState(data.days);
  const [months, setMonths] = useState(data.months);
  const [isSearchable, setIsSearchable] = useState(true); // React Select Parameter for search on select

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="flex">
      <div className="flex-1">
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={days}
          placeholder="Giorno"
          isSearchable={isSearchable}
          onChange={(e) => manageState(subject, "day", e.value, dataState)}
        />
      </div>
      <div className="flex-1">
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={months}
          placeholder="Mese"
          isSearchable={isSearchable}
          onChange={(e) => manageState(subject, "month", e.value, dataState)}
        />
      </div>
      <div className="flex-1">
        <Select
          className="basic-single"
          classNamePrefix="select-year"
          options={years}
          placeholder="Anno"
          isSearchable={isSearchable}
          onChange={(e) => manageState(subject, "year", e.value, dataState)}
          classNames={{
            control: ({ isDisabled, isFocused }) =>
              classNames(
                !isDisabled && isFocused && "border-purple-800",
                isFocused && "shadow-[0_0_0_1px] shadow-purple-800"
              ),
            option: ({ isDisabled, isFocused, isSelected }) =>
              classNames(isDisabled && "hover:cursor-not-allowed add-after"),
          }}
        />
      </div>
    </div>
  );
}
