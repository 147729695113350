import { useState, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import getMenu from "../../utils/config/getMenu";
import UserContext from "../../store/auth-context";
import { When } from "react-if";

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const userCtx = useContext(UserContext);

  const handleToggle = () => {
    setToggleMenu((prev) => !prev);
    const hiddenToggleMenu = document.getElementById("hidden-toggle-menu");
    const bodyElement = document.getElementsByTagName("body");
    hiddenToggleMenu.classList.toggle("hidden");
    bodyElement[0].classList.toggle("overflow-hidden");
  };

  const printMenu = () => {
    return getMenu.map((itemMenu) => {
      if (
        itemMenu.name === "ABBONATI/ACCEDI" &&
        userCtx.isValidPianoUser() === true
      ) {
        return;
      }
      return (
        <div
          className={`border-b py-5 text-center  md:border-b-0`}
          key={itemMenu.name}
        >
          <a
            target={itemMenu.targetBlank ? "_blank" : "_self"}
            href={itemMenu.url}
            className={`${
              itemMenu.name === "ABBONATI/ACCEDI" && "text-color-red"
            } font-body font-semibold text-base pr-5 text-black hover:text-color-red hover:no-underline hover:cursor-pointer focus:no-underline focus:text-color-red `}
          >
            {itemMenu.name}
          </a>
        </div>
      );
    });
  };

  return (
    <>
      <header className="border-b flex justify-between flex-1 ">
        <div className="flex items-center pl-6 md:hidden">
          {!toggleMenu && (
            <FaBars
              className="text-3xl cursor-pointer"
              onClick={() => handleToggle()}
            />
          )}
          {toggleMenu && (
            <AiOutlineClose
              className="text-3xl cursor-pointer"
              onClick={() => handleToggle()}
            />
          )}
        </div>
        <div className="mx-auto md:mx-0 flex items-center">
          <a className="text-center w-full block pl-5" href="/">
            <img
              src="https://static.gruppocdt.ch/shared-files/miscellaneous/cdt-logo.svg"
              alt="logo corriere del ticino"
              className="mx-auto max-w-[230px] py-3 -ml-6 md:ml-0"
            />
          </a>
        </div>
        <div className=" hidden md:flex items-center">{printMenu()}</div>
      </header>
      <div className="h-screen w-full hidden" id="hidden-toggle-menu">
        {printMenu()}
      </div>
    </>
  );
};

export default Header;
